:root {
    --primary-color: #484848;
    --secondary-color: #484848;
  }
  $primary-color: var(--primary-color);
  $secondary-color: var(--secondary-color);
  $border-color: #e7e7e780;

.navTab {
    width: 250px;
    display: flex;
    border-right: 1px solid $border-color;
    padding: 20px;
    align-items: center;
    height: 80px;
    img {
      height: 100%;
      margin-right: 10px;
      width: 40px;
    }
    span {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .rightCorner {
    margin-left: auto;
    flex: 0 0 79px;
    border-left: 1px solid $border-color;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 50%;
      width: auto;
      max-width: 60%;
      &:hover{
        -ms-transform: scale(1.2); /* IE 9 */
        -webkit-transform: scale(1.2); /* Safari 3-8 */
        transform: scale(1.2); 
        transition: 0.3s all ease-in-out;
      }
    }
  }
  
  .tab {
    width: 0;
    flex: 1;
    height: 50px;
    margin: 0;
    display: flex;
    .tabitm {
      padding: 0;
      cursor: pointer;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      font-size: 12px;
      h6 {
        width:max-content;
        padding: 0 30px !important;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        line-height: 1;
        font-weight: 400;
        margin: 0;
        color: rgba(0, 10, 0, 0.5);
      }
      &::after {
        transition: 300ms ease all;
        margin-top: auto;
        content: '';
        height: 3px;
        border-radius: 2px;
        background: $primary-color;
        width: 0;
      }
      &:hover {
        opacity: 0.9;
        &::after {
          width: 100%;
          background: gray;
        }
        h6 {
          color: $secondary-color;
          font-size: 14px;
          font-weight: 600;
        }
      }
      &.true {
        opacity: 1;
        h6 {
          color: $secondary-color;
          font-size: 14px;
          font-weight: 600;
        }
        &::after {
          width: 100%;
        }
      }
    }
  }
  .nav-user{
    font-size: 15px;
    font-weight: 800;
    color: #18191D;
    border: solid 1px #e7e7e780;
    border-width: 0px 0px 0px 1px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-user:hover{
    cursor: pointer;
  }
  