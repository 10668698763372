.navbar {
  height: 90px;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  background: white;
  z-index: 2;
  position: relative;
  transition: all linear 0.3s;

  & > .logo {
    width: 25%;

    & > img {
      height: 3.5rem !important;
    }
  }

  & > .navigations {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    font-size: 1rem;
    font-weight: 350;
    width: 50%;

    & > div {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all linear 0.1s;

      &:hover {
        text-decoration: underline;
        color: var(--main-color);
      }
    }
  }

  & > .navButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    font-size: 1rem;
    font-weight: 500;
    width: 25%;

    & > div {
      padding: 0.65rem 0;
      border: 1px solid var(--main-color);
      border-radius: 5px;
      cursor: pointer;
      max-width: 150px;
      width: 50%;
      text-align: center;
      transition: all linear 0.3s;
    }

    & > :nth-child(1) {
      background: #191F41 ;
      border: 1px solid #191F41 ;
      color: white;
      // color: var(--main-color);

      &:hover {
        background: white;
        color: #191F41 ;
      }
    }

    & > :nth-child(2) {
      background: var(--main-color);
      color: white;

      &:hover {
        background: #8d5407;
        color: var(--main-color);
      }
    }
  }

  & > .navHamberger {
    display: none;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    background: var(--font-color);
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }

  & > .mobileMenu {
    position: absolute;
    // height: 100vh;
    inset: -0.8px;
    top: 100%;
    background: white;
    border-top: 1px solid #e5e7eb;

    & > .menuItems {
      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem;
        font-size: 1.4rem;
        font-weight: 550;
        border-bottom: 1px solid #e5e7eb;
      }
    }

    & > .btnsContainer {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 2rem;
      padding: 0 1.5rem;

      & > div {
        font-size: 1.2rem;
        padding: 0.8rem;
        text-align: center;
        border-radius: 5px;
        font-weight: 500;
        border: 1px solid #e5e7eb;
      }

      & > :nth-child(1) {
        background: var(--font-color);
        color: white;
      }
    }
  }
}

.fixedNavbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
}

@media screen and (max-width: 800px) {
  .navbar {
    & > .navigations {
      display: none;
    }

    & > .navButtons {
      display: none;
    }

    > .navHamberger {
      display: flex;
    }
  }
}
