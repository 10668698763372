@import "../../assets/scss/mixin";

.headerStyle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #18191D;
    padding-bottom: 35px;
}

.filterCards{
    background: #FFFFFF;
    border: 0.5px solid #E7E7E7;
    padding: 30px;
    margin-bottom: 18px;
    height: 83px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cardTitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 19px;
        line-height: 23px;
        display: flex;
        align-items: center;

        color: #18191D;
    }
    .cardSubTitle{
        padding-top: 5px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        display: flex;
        align-items: center;
        
        color: #18191D;
    }
    &:hover{
        transition: all 0.2s ease-in-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.1);
        top: -4px;
        // border: 1px solid #18191D;
        // border-radius: 10px;
        background: white;
        cursor: pointer;
       
    }
    
}

.bottomButtonFull{
    position: absolute;
    bottom: 0;
    right: 0;
    width:100%;
    height: 65px;
    background: #18191D;
    border: 0.5px solid #18191D;
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;

    // opacity: 0.25;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    // @include btn-animate(white);
    &:hover{
        .fontAnimate{
            transform: scale(1.1);
            transition: 0.3s all ease-in-out;
        }
    }
    
}

.bottomButton{
    position: absolute;
    bottom: 0;
    right: 0;
    width:50%;
    height: 65px;
    background: #18191D;
    border: 0.5px solid #18191D;
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;

    // opacity: 0.25;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover{
        .fontAnimate{
            transform: scale(1.1);
            transition: 0.3s all ease-in-out;
        }
    }
}

.bottomButtonWhite{
    position: absolute;
    bottom: 0;
    left: 0;
    width:50%;
    height: 65px;
    background: white;
    border: 0.5px solid #E7E7E7;
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #18191D;

    // opacity: 0.25;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover{
        .fontAnimate{
            transform: scale(1.1);
            transition: 0.3s all ease-in-out;
        }
    }
   
}

.bottomButtonDisabled{
    position: absolute;
    bottom: 0;
    width:100%;
    height: 65px;
    background: #18191D;
    border: 0.5px solid #18191D;
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;

    color: lightgray;

    // opacity: 0.25;
    display: flex;
    justify-content: center;
    align-items: center;
    // opacity: 0.3;
}

.filterCoinCard{
    display: flex;
    flex-direction: column;
    border: 0.5px solid #E7E7E7;
    border-width: 0px 0.5px 0.5px 0.5px;
    height: 50vh;
    overflow-y: scroll;
    
    .cardBody{
        display: flex;
        align-items: center;
        padding:30px;
        border-bottom: 0.5px solid #E7E7E7;
        cursor: pointer;
        .coinImage{
            width: 33px;
            height: 33px;
            margin-left: 0px;
            transition: margin-left 0.3s ease-in-out; 
        }
        .coinDetail{
            padding-left: 7px;  
            transition: padding-left 0.3s ease-in-out; 
        }
        &:hover{
           .coinDetail{
               padding-left: 9px;
            //    transition: all;
                // transition: 0.3s all ease-in-out;
                cursor: pointer;
            }
            .coinImage{
                margin-left: 9px;
            }
        }
    }
   
}

.hoverText{
    &:hover{
        font-weight: 600;
        cursor: pointer;
    }
}

.filterTitle{
    margin: 30px 0;
    margin-top: 0px;
    font-weight: 600;
    font-size: 18px;
}

.filterSearch{
    height: 75px;
    border: 1px solid #E7e7e7;
    display: flex;
    margin: 0;
    input{
        width: 100%;
        border: none;
        padding-left: 30px;
    }
}