.settings {
  padding: 2rem;
  height: calc(100%);
  width: 100%;
  padding-bottom: 0;
  display: flex;
  gap: 1.5rem;

  &>div {
    background: white;
    padding: 2rem;
    height: 100%;
  }

  &>.leftMenu {
    width: 400px;

    &>.heading {
      font-size: 1.25rem;
      font-weight: 550;
    }

    &>.menuItems {
      display: flex;
      flex-direction: column;
      gap: 1.75rem;
      margin-top: 1.6rem;

      &>div {
        font-size: 0.9rem;
        font-weight: 500;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          color: var(--main-color);
        }
      }

      &>.selectedItem {
        font-weight: 550;
        color: var(--main-color);
      }
    }
  }

  &>.rightDashboard {
    width: -webkit-fill-available;
    overflow-y: scroll;
    max-width: 1200px;
    margin: auto;
    border-radius: 10px;
  }
}

.profileParent {



  &>.profileName {
    font-size: 1.5rem;
    font-weight: 550;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 2rem;

    &>img {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      border: 1px solid #f5f5f5;
    }
  }

  &>.profileDetails {
    padding-right: 1rem;

    .eachProfileItem {
      &>.title {
        font-size: 0.9rem;
        color: var(--font-color);
        padding-bottom: 1rem;
        font-weight: 500;
        white-space: nowrap;
      }

      &>.inputValue {
        position: relative;
        width: 100%;

        &>div {
          height: 100%;
          width: 100%;

          &>.imageBox {
            border: 0.5px solid #e7e7e7;
            border-radius: 5px;
            // height: 100%;
            aspect-ratio: 1/1;

            &>img {
              border-radius: 5px;
              width: 100%;
              height: 100%;
            }
          }

          &>.valueBox {
            padding: 1rem 1.4rem;
            background: #f5f5f5;
            border-radius: 10px;
            font-weight: 400;
            margin-top: 0.5rem;
            font-size: 0.9rem;
          }

          &>.inputBox {
            padding: 1rem 1.4rem;
            border-radius: 10px;
            font-weight: 550;
            margin-top: 0.5rem;
            font-size: 0.9rem;
            outline: none;
            border: 0.5px solid #e7e7e7;
            width: 100%;

            &:focus {
              border: 1px solid black;
            }
          }

          &>.editContainer {
            &>.editIcon {
              position: absolute;
              top: -15px;
              right: -15px;
              padding: 8px;
              background: white;
              height: 2rem;
              width: 2rem;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              border: 0.5px solid #e7e7e7;

              &>img {
                height: 0.8rem;
                width: 0.8rem;
              }
            }

            &>.buttonsContainer {
              display: flex;
              gap: 1rem;
              align-items: center;
              margin-top: 1.5rem;

              &>button {
                all: unset;
                padding: 0.8rem 2rem;
                border-radius: 5px;
                font-size: 0.9rem;
                font-weight: 500;
                cursor: pointer;
                color: white;
                width: 50%;
                text-align: center;
              }

              &> :nth-child(1) {
                background: #ea4335;
              }

              &> :nth-child(2) {
                background: #34a853;
              }
            }
          }
        }
      }
    }

    &>.topContainer {
      display: grid;
      grid-template-columns: 0.45fr 1fr;
      padding-top: 2rem;
      // border-top: 1px solid #e9ebf3;
      gap: 2rem;

      &>div {
        &>img {
          // width: 250px;
        }
      }

      &>.valuesDiv {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;

        &>div {
          width: 100%;
        }
      }
    }

    &>.bottomProfile {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 2rem 0;
      gap: 2rem;
    }
  }
}

.ownerContainer {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &>.ownerItems {
    border: 2px solid #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    width: 100%;
    height: 4rem;
    padding: 0 2rem;
    transition: all linear 0.3s;

    &>img {
      border: 1.5px solid white;
      border-radius: 50%;
    }

    &>div {
      width: 100%;

      &>span {
        height: 100%;
        display: block;
      }
    }

    &>.profileDetails {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 0.9rem;
      width: max-content;

      &>img {
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 50%;
        border: 0.5px solid #e5e5e5;
      }
    }

    &>.buttonsContainer {
      display: flex;
      gap: 1rem;
      align-items: center;
      padding: 10px 0;
      width: max-content;
      padding: 0;

      &>div {
        height: 100%;
        border-radius: 5px;
        padding: 10px 1rem;
        border: 1px solid #e9ebf3;
        font-size: 0.8rem;
        font-weight: 500;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          background: var(--main-color);
          color: white;
        }
      }
    }
  }

  &>.ownerItemsHover {
    &:hover {
      background: var(--main-color);
      color: white;
    }
  }

  &>.line {
    height: 1px;
    background: #e5e5e5;
    width: 100%;
    margin: 1rem 0;
  }
}

.createNewOrganization {
  &>.leftCard {
    width: 60%;
    // padding: 3rem;
    border-right: 1px solid #e7e7e7;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &>img {
      height: 6rem;
      object-fit: contain;
      width: max-content;
    }

    &>.credentialsContainer {
      display: flex;
      flex-direction: column;
      gap: 1.7rem;

      &>.title {
        font-size: 1.5rem;
        font-weight: 520;
        margin-bottom: 2rem;
      }

      .inputContainer {
        border: 0.5px solid #e5e5e5;
        padding: 1rem;
        background: #f5f5f5;
        font-size: 1rem;
        display: block;
        outline: none;
        font-weight: 400;
        border-radius: 5px;
        max-width: 450px;
        width: 80%;
      }

      .loginMessage {
        font-size: 1rem;
        font-weight: 500;
        color: #d6a86b;
        padding: 0.25rem 0;
      }

      .submitBtn {
        border-radius: 5px;
        max-width: 450px;
        width: 80%;
        padding: 1rem;
        font-size: 1rem;
        outline: none;
        font-weight: 400;
        color: white;
        background: var(--main-color);
        border: 1px solid var(--main-color);
        outline: none;
        border: none;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          scale: 1.05;
        }
      }

      .normalBtn {
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        text-align: center;
        max-width: 450px;
        width: 80%;
        transition: all linear 0.3s;

        &:hover {
          text-decoration: underline;
        }
      }

      &>.seperator {
        font-weight: 600;
        font-size: 1rem;
        position: relative;
        max-width: 450px;
        width: 80%;
        text-align: center;

        &>span {
          background: white;
          padding: 0 0.5rem;
          position: relative;
          z-index: 1;
        }

        &::before {
          position: absolute;
          content: "";
          height: 1px;
          background: #e5e5e5;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .transparentBtn {
        border-radius: 5px;
        max-width: 450px;
        width: 80%;
        padding: 1rem;
        font-size: 1rem;
        outline: none;
        font-weight: 550;
        color: var(--font-color);
        border: 1px solid #e5e5e5;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        transition: all linear 0.3s;

        &>img {
          width: 1rem;
          height: 1rem;
        }

        &:hover {
          background: #e5e5e5;
        }
      }
    }
  }

  &>.userRegistration {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 100%;

    &>.userRegistrationContainer {
      width: 100%;

      &>.flexParent {
        display: flex;
        align-items: center;
        gap: 1rem;

        &>input {
          width: 50%;
          max-width: unset;
        }

        &>textarea {
          border: 0.5px solid #e5e5e5;
          padding: 1rem;
          background: #f5f5f5;
          font-size: 1rem;
          display: block;
          outline: none;
          font-weight: 450;
          border-radius: 5px;
          width: 50%;
          font-family: inherit;
          max-width: unset;
        }

        &>button {
          width: 50%;
          max-width: unset;
          display: flex;
          justify-content: center;
        }

        &>div {
          height: 3.25rem;
          width: 50%;

          &>div {
            background: #f5f5f5;
            border: 0.5px solid #e5e5e5;
            max-width: unset;
          }
        }

        &>.profileContainer {
          display: flex;
          align-items: center;
          gap: 1rem;
          color: rgba(0, 0, 0, 0.9);
          font-size: 0.9rem;
          font-weight: 450;
          width: 50%;
          height: unset;

          &>.imageInput {
            width: -webkit-fill-available;
            background: none;
            border: none;

            img {
              width: 100%;
              height: 6rem;
              border-radius: 5px;
              border: 0.5px solid #e5e5e5;
            }

            &>.imageLabel {
              white-space: nowrap;
              text-align: center;
              margin-top: 1rem;
              color: rgba(0, 0, 0, 0.9);
              font-size: 0.9rem;
              font-weight: 450;
              cursor: pointer;
            }

            &>.emptyProfile {
              width: 100%;
              height: 6rem;
              border-radius: 5px;
              background: #f5f5f5;
              border: 0.5px solid #e5e5e5;
              display: flex;
              justify-content: center;
              align-items: center;

              &>img {
                width: 3rem;
                height: 3rem;
                border: none;
              }

              @keyframes heartbeat {
                0% {
                  transform: scale(1);
                }

                50% {
                  transform: scale(1.2);
                }

                100% {
                  transform: scale(1);
                }
              }
            }

            &>.emptyProfileLength {
              height: 6rem;
              width: 100%;
              border-radius: 5px;
              background: #f5f5f5;
              border: 0.5px solid #e5e5e5;
              display: flex;
              justify-content: center;
              align-items: center;

              &>img {
                width: 3rem;
                height: 3rem;
                border: none;
              }

              @keyframes heartbeat {
                0% {
                  transform: scale(1);
                }

                50% {
                  transform: scale(1.2);
                }

                100% {
                  transform: scale(1);
                }
              }
            }
          }

          &>.editIcon {
            all: unset;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.profileName {
  font-size: 1.5rem;
  font-weight: 550;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 2rem;

  &>img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 1px solid #f5f5f5;
  }
}