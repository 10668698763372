.featurePage {
  // height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 2.5rem;

  & > .feature {
    max-width: 1280px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-bottom: 0;
    width: 100%;

    & > .platformTitle {
      background: var(--font-color);
      color: white;
      padding: 0.5rem 1.5rem;
      font-size: 1.2rem;
      font-weight: 500;
      width: max-content;
      border-radius: 3px;
    }

    & > .title {
      font-size: 3rem;
      font-weight: 500;
      width: 100%;
      // max-width: 800px;
      text-align: center;
      font-family: "Montserrat";
      text-align: center;
    }

    & > .desc {
      font-size: 1.4rem;
      font-weight: 380;
      line-height: 1.5;
      font-family: "Montserrat";
      text-align: center;
    }

    & > .platform {
      height: 60vh;
      width: 100%;
      display: flex;
      margin-top: 2rem;

      & > div {
        // border-radius: 10px;
        height: 100%;
      }

      & > .leftContainer {
        width: 600px;
        max-width: 40%;
        // max-width: 500px;

        & > .normalItem {
          height: 20%;
          border: 1px solid #e5e7eb;
          // border-radius: 10px;
          transition: all linear 0.3s;

          & > .fullItem {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            height: 100%;
            padding: 1.5rem;

            & > .title {
              font-size: 1.3rem;
              font-weight: 550;
              font-family: "Montserrat";
            }

            & > .desc {
              font-size: 1rem;
              line-height: 1.5;
              font-weight: 350;
              font-family: "Montserrat";
            }

            & > .linkBtn {
              font-family: "Montserrat";
              font-size: 0.9rem;
              font-weight: 500;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 0.6rem;
              cursor: pointer;
              color: white;
              border-radius: 35px;
              padding: 0 1rem;
              margin-top: auto;
              transition: all linear 0.3s;

              & > div {
                padding: 0.8rem 1rem;
              }

              & > span {
                // background: rgb(34 40 30/1);
                padding: 5px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-weight: 450;
                font-size: 1.5rem;
                transition: all linear 0.3s;
              }

              &:hover {
                background: white !important;
                color: var(--link-hover-color);

                & > span {
                  color: var(--link-hover-color);
                }
              }

              // &:hover {
              //   & > span {
              //     background: white;
              //     color: rgb(34 40 30/1);
              //     translate: 10px;
              //   }
              // }
            }
          }

          & > .itemTitle {
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.2rem;
            font-weight: 500;
            cursor: pointer;
            font-family: "Montserrat";
            padding: 1.5rem;
            transition: all linear 0.3s;

            &:hover {
              background: var(--bg-hover-item);
              color: white;
            }

            & > span {
              border: 1px solid #e5e7eb;
              width: 2rem;
              height: 2rem;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 300;
              text-align: center;
            }
          }
        }

        & > .selectedItem {
          height: 60%;
          border: 1px solid #e5e7eb;
        }
      }

      & > .rightContainer {
        width: -webkit-fill-available;
        // border: 1px solid #e5e7eb;
        position: relative;
        background: rgb(66, 141, 66);
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        transition: all linear 0.3s;

        & > div {
          border: 1px solid #e5e7eb;
          height: 70px;
          width: 20%;
          min-width: 100px;
          border-radius: 10px;
        }
      }
    }
  }
}

.platformMobile {
  // height: 60vh;
  width: 100%;
  display: flex;
  margin-top: 2rem;

  & > div {
    // border-radius: 10px;
    height: 100%;
  }

  & > .leftContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    // max-width: 500px;

    & > .normalItem {
      // height: 20%;
      border: 1px solid #e5e7eb;
      text-align: center;
      border-radius: 15px;
      transition: all linear 0.3s;

      & > .fullItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        height: 100%;
        padding: 1.5rem;

        & > .title {
          font-size: 1.3rem;
          font-weight: 550;
          font-family: "Montserrat";
        }

        & > .desc {
          font-size: 1rem;
          line-height: 1.5;
          font-weight: 350;
          font-family: "Montserrat";
        }

        & > .linkBtn {
          font-family: "Montserrat";
          font-size: 0.9rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.6rem;
          cursor: pointer;
          color: white;
          border-radius: 35px;
          padding: 0 1rem;
          margin-top: auto;
          transition: all linear 0.3s;

          & > div {
            padding: 0.8rem 1rem;
          }

          & > span {
            // background: rgb(34 40 30/1);
            padding: 5px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-weight: 450;
            font-size: 1.5rem;
            transition: all linear 0.3s;
          }

          &:hover {
            background: white !important;
            color: var(--link-hover-color);

            & > span {
              color: var(--link-hover-color);
            }
          }

          // &:hover {
          //   & > span {
          //     background: white;
          //     color: rgb(34 40 30/1);
          //     translate: 10px;
          //   }
          // }
        }
      }

      & > .itemTitle {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
        cursor: pointer;
        font-family: "Montserrat";
        padding: 1.5rem;
        transition: all linear 0.3s;

        &:hover {
          background: var(--bg-hover-item);
          color: white;
        }

        & > span {
          border: 1px solid #e5e7eb;
          width: 2rem;
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 300;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .featurePage {
    flex-direction: column;

    & > .feature {
      padding: 0 1.5rem;
    }
  }
}
