.container {
  height: 100%;
  overflow-y: scroll;
  padding: 33px 17px;
}
.titleWrapper {
  display: flex;
  // justify-content: center;
  align-items: center;
}
.editWrapper {
  display: flex;
  align-items: center;
  div {
    font-size: 0.75rem;
    color: #1876d3;
    padding-left: 3px;
  }
}
.title {
  font-size: 1.5rem;
  font-weight: 600;
  padding-right: 1.5rem;
  color: #191f41;
}
.flexContainer {
  display: flex;
  gap: 1rem;

  & > div {
    width: 50%;
  }
}
.secondTitleWrapper {
  display: flex;
  align-items: center;
  padding-left: 24px;

  & > div {
    min-width: 150px;
  }
}
.firstGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  .gridCard {
    height: 100px;
    background-color: #ffffff;
    padding: 1rem 1.125rem;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    border: 1px solid #e7e7e7;
  }
}
.secondGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  .gridCard {
    height: 100px;
    background-color: #ffffff;
    padding: 1rem 1.125rem;
    border-radius: 16px;
    border: 1px solid #e7e7e7;
  }
}
.gridrow1 {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  .title {
    font-size: 0.8rem;
    font-weight: 600;
  }
}

.gridrow2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
}
.gridrow2Left {
  font-size: 1.5rem;
  font-weight: 600;
}
.gridrow2Right {
  font-size: 0.875rem;
  font-weight: 400;
}
.thirdGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  height: calc(100% - 70px);
}
.thirdGridLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  border-radius: 16px;
  .lab1 {
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.5rem 0px;
    width: 240px;
    text-align: center;
  }
  .lab2 {
    font-size: 0.75rem;
    font-weight: 400;
    width: 240px;
    text-align: center;
    opacity: 50%;
  }
}
.thirdGridRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  border-radius: 16px;
  height: calc(100% - 57px);
  position: relative;

  div {
    font-size: 14px;
    font-weight: 400;
    padding-top: 20px;
  }

  & > :nth-child(2) {
    position: absolute;
    inset: 0;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    opacity: 0.95;
    padding: 0;
    pointer-events: none;

    & > img {
      height: 4rem;
    }
  }
}
