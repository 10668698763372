// @import "../../../../static/scss/mixin";

.monthName{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    width: 180px;
    color: #18191D;
    padding-right: 14px;

}
.arrowButtons{
    width: 25px; 
    height: 25px;
    cursor: pointer;
    transition: all 20ms ease-in-out;
    &:hover{
        width:27px;
        height: 27px;        
    }
}
.filterOpen{
    position: fixed;
    right: 40px;
    top:125px;
    width: 207px;
    // height: 300px;
    border: 1px solid #E9EBF3;
    border-radius: 5px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 25px;
    & > div{
        
        padding: 0px 19px;
        padding-bottom: 28px;
        &:hover{
            font-weight: 700;
            cursor: pointer;
        }
    }
}
.filterClosed{
    position: fixed;
    right: 40px;
    top:68px;
    width: 180px;
    height: 39px;
    border: 0.25px solid #E9EBF3;
    border-radius: 5px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px 20px;
    font-weight: 600;
    cursor: pointer;
    font-size: 13px;
   
}
.calendarBody{

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    // height: 
}

.singeDayBody{
    // display: flex;
    // overflow-x: scroll;
    padding: 2vh 1.5vw;
    .singleDayCard{
        min-width: 392px;
        border-right: 0.5px solid #E7E7E7;
        padding: 30px;
        font-family: 'Montserrat';
        font-style: normal;
        line-height: 160%;
        letter-spacing: 0.002em;
        overflow-y: scroll;
       .titleHead{
        font-weight: 700;
        font-size: 20px;
        color: #18191D;
       }
       .title{
        font-weight: 800;
        font-size: 16px;
       }
       .subTitle{
        font-weight: 300;
        font-size: 10px;
       }
    }
    .clientCard{
        height: 85px;
        padding: 27px 30px;
        margin-bottom: 15px;
        border: 1px solid #E9EBF3;
        border-radius: 10px;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

        &:hover{
            border: 1px solid #C57E50;
        }
    }
}
    .cardAvatar{
        width: 28px;
        height: 28px;
        background: lightgray;
        border-radius: 50%;
        margin-right: 10px;
    }
.col1{
    // text-align: center;
    :nth-child(1){
        color: #5F6163;
        font-size: 10px;
        font-weight: 400;
    }
    :nth-child(2){
        color: #C57E50;
        font-size: 15px;
        font-weight: 700; 
    }
}

.col2{
    display: flex;
    // justify-content: center;
    align-items: center;
    :nth-child(2){
       
        font-size: 12px;
        font-weight: 600;
    }
}
.col3{
    display: flex;
    // justify-content: center;
    align-items: center;
    .title{
        font-size: 12px;
        font-weight: 600;
    }
    .subTitle{
        font-size: 12px;
        font-weight: 400;
        padding-top: 3px;
    }
}
.col7{
    display: flex;
    gap: 13px;
    :nth-child(1){
       width: 50%; 
       height: 28px;
       background: #34A853;
       display: flex;
       justify-content: center;
       align-items: center;
       font-size: 10px;
       font-weight: 700;
       color: white;
       border-radius: 5px;
    }
    :nth-child(2){
        width: 50%; 
        height: 28px;
        border: 1px solid #E9EBF3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: 500;
        color: #5F6163;
        border-radius: 5px;
    }
}