.loginPage {
  width: 100vw;
  height: 100vh;
  background: #fbfbfb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .topNav {
    position: fixed;
    top: 0;
    left: 0;
    height: 3rem;
    display: flex;
    align-items: center;
    width: max-content;

    & > a {
      font-size: 0.85rem;
      font-weight: 550;
      text-decoration: underline;
      cursor: pointer;
      padding: 0 1rem;
      color: var(--font-color);
    }
  }

  & > .loginCard {
    background: white;
    display: flex;
    border: 1px solid #e7e7e7;
    width: max-content;
    height: max-content;
    max-height: 90%;
    position: relative;

    & > .leftCard {
      width: 100%;
      min-width: 550px;
      padding: 3rem;
      border-right: 1px solid #e7e7e7;
      padding-bottom: 4rem;
      display: flex;
      gap: 2rem;
      flex-direction: column;
      justify-content: center;
      padding-right: 3rem;

      & > .heartBeatLoading {
        animation: heartbeat 1s infinite;
      }

      @keyframes heartbeat {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.2);
        }

        100% {
          transform: scale(1);
        }
      }

      & > img {
        height: 6rem;
        object-fit: contain;
        width: max-content;
      }

      & > .title {
        font-size: 1.2rem;
        font-weight: 500;
      }

      & > .allRegistrationOptions {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & > .eachOption {
          display: flex;
          gap: 1rem;
          align-items: center;
          cursor: pointer;
          padding: 5px;
          transition: all linear 0.3s;

          & > .imageContainer {
            height: 4rem;
            width: 4rem;
            border: 1px solid #e7e7e7;
            padding: 5px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            & > img {
              height: 2rem;
              width: 2rem;
            }
          }

          & > .title {
            & > :nth-child(1) {
              font-size: 1rem;
              font-weight: 550;
              margin-bottom: 6px;
            }

            & > :nth-child(2) {
              font-size: 0.8rem;
              font-weight: 400;
            }
          }

          &:hover {
            background: #e7e7e7;
          }
        }
      }

      & > .credentialsContainer {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.7rem;

        & > .title {
          font-size: 1.5rem;
          font-weight: 520;
        }

        & > .isExistContainer {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 15px;

          & > .isExistLight {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background: #d80027;
            transition: all linear 0.3s;
          }
        }

        .inputContainer {
          border: 0.5px solid #e5e5e5;
          padding: 1rem;
          background: #f5f5f5;
          font-size: 1rem;
          display: block;
          outline: none;
          font-weight: 400;
          border-radius: 5px;
          // max-width: 450px;
          width: 100%;
        }

        & > .profileContainer {
          display: flex;
          gap: 10px;
          align-items: center;
          border: 1px solid #e5e7eb;
          border-radius: 5px;
          padding: 1rem;

          & > :nth-child(1) {
            & > img {
              width: 3rem;
              height: 3rem;
              border-radius: 50%;
              border: 0.5px solid #e5e5e5;
            }
          }

          & > :nth-child(2) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 2px;
            height: 3rem;

            & > :nth-child(1) {
              font-size: 1.1rem;
              font-weight: 550;
            }
            & > :nth-child(2) {
              font-size: 0.8rem;
              font-weight: 350;
            }
          }
        }

        & > .seperator {
          font-weight: 600;
          font-size: 1rem;
          position: relative;
          width: 100%;
          text-align: center;

          & > span {
            background: white;
            padding: 0 0.5rem;
            position: relative;
            z-index: 1;
          }

          &::before {
            position: absolute;
            content: "";
            height: 1px;
            background: #e5e5e5;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        & > .loginMessage {
          font-size: 1rem;
          font-weight: 500;
          color: #d6a86b;
          padding: 0.25rem 0;
        }

        & > .message {
          font-size: 1rem;
          color: var(--font-color);
          font-weight: 450;
          text-decoration: underline;
        }

        & > .buttonsContainer {
          display: flex;
          align-items: center;
          // max-width: 450px;
          width: 100%;
          gap: 2rem;
          margin-top: 3rem;

          & > button {
            width: 50%;
          }
        }

        .submitBtn {
          border-radius: 5px;
          max-width: 450px;
          width: 80%;
          padding: 1rem;
          font-size: 1rem;
          outline: none;
          font-weight: 400;
          color: white;
          background: var(--main-color);
          border: 1px solid var(--main-color);
          outline: none;
          border: none;
          cursor: pointer;
          transition: all linear 0.3s;

          &:hover {
            scale: 1.05;
          }
        }

        .blueBtn {
          border-radius: 5px;
          max-width: 450px;
          width: 80%;
          padding: 1rem;
          font-size: 1rem;
          outline: none;
          font-weight: 550;
          color: white;
          background: var(--font-color);
          border: 1px solid var(--font-color);
          outline: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          transition: all linear 0.3s;

          &:hover {
            scale: 1.05;
          }

          & > img {
            width: 1rem;
            height: 1rem;
          }
        }

        & > .otpInputParent {
          max-width: 600px;
          margin-bottom: 2rem;
        }

        .otpInputWrapper {
          width: 100%;
          justify-content: space-between;

          & > div {
            height: 70px;

            input {
              width: 70% !important;
              height: 100%;
              border: 2px solid #e7e7e7;
              border-radius: 8px;
              font-size: 28px;
            }
          }
        }

        .conditions {
          width: 100%;
          margin-top: 1rem;
          margin-bottom: 2rem;
          background: #fffdf2;
          border-radius: 15px;
          display: flex;
          flex-direction: column;

          .eachCondition {
            display: flex;
            width: 100%;
            padding: 15px 23px;
            align-items: center;
            justify-content: space-between;
            font-size: 0.9rem;
            font-weight: 450;
            .indicator {
              width: 1.1rem;
              height: 1.1rem;
              border: none;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
