.global-dashboard {
  padding: 2rem;
  height: 100%;
  width: 100%;
  overflow: hidden;
  max-width: 1200px;
  margin: auto;

  .drowpside-right {
    /* border: 1px solid #E9EBF3; */
    padding: 1rem;
    border-radius: 10px;
    /* position: absolute; */
    background: white;
    z-index: 99;
    top: 46px;
    right: 0rem;
    height: calc(100vh - 55px);
    overflow: scroll;
    padding-top: 2rem;
    width: 100%;
  }

  .label-box-header {
    font-family: Montserrat;
    font-size: 35px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    color: var(--font-color);
    margin-bottom: 2rem;
    text-transform: capitalize;
  }

  .right-side {
    display: flex;
    align-items: center;
    gap: 1rem;

    .blueBtn {
      font-size: 13px;
      font-weight: 700;
      height: 39px;
      padding: 0 2.5rem;
      background: #191f41;
      border-radius: 5px;
      cursor: pointer;
      color: white;
      display: flex;
      align-items: center;

      & > a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  .top-section-golbal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    position: relative;
  }

  .totalCount {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    margin-bottom: 2rem;

    & > div {
      padding: 1rem;
      border: 1px solid #e1e1e1;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      background: white;

      & > .title {
        font-size: 0.8rem;
      }

      & > .value {
        font-size: 2rem;
        font-weight: 550;
      }
    }
  }

  .shortBy {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.85px;
    text-align: left;
    color: var(--font-color);
  }

  .addLabel {
    background: linear-gradient(270deg, #f04e8c 0%, #f8ba4e 103.98%);

    height: 39px;
    padding: 1.1rem;
    padding: 1.1rem 2.5rem;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 700;
    line-height: 15.85px;
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: min-content;
    white-space: no-wrap;
    white-space: nowrap;
    flex-wrap: nowrap;
    cursor: pointer;

    svg {
      margin-right: 4px;
    }

    &:hover {
      transform: scale(1.03);
    }
  }

  .dropdownlist {
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    height: 39px;
    padding: 1.1rem;
    border-radius: 5px;
    border: 0.5px solid #e9ebf3;
    width: min-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--font-color);

    svg {
      margin-left: 16px;
    }

    &:hover {
      transform: scale(1.03);
    }
  }

  .left-side {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .label-box {
    border: 0.5px solid #e9ebf3;
    height: 39px;
    font-family: Montserrat;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    text-align: left;
    color: var(--font-color);
    text-transform: capitalize;
    border-radius: 5px;
    border-radius: 5px;
    width: min-content;
    display: flex;
    align-items: center;
    padding: 1.1rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.03);
    }
  }

  tbody {
    position: relative;
    top: 0.5rem;
  }

  .handle-table {
    border: 1px solid #e9ebf3;
    padding-top: 1rem;
    border-radius: 10px;
    height: calc(100vh - 237px);
  }

  .g-table-sec {
    padding: 1rem;
    border-radius: 10px;
    overflow: auto;
    height: 100%;
    padding-top: 0rem;
    background-color: white;
  }

  .table-section {
    height: 100%;
  }

  table {
    border-spacing: 0px 11px;
    border-collapse: separate;
    min-width: 240%;
    table-layout: fixed;
    width: max-content;

    th {
      border-collapse: collapse;
      max-width: 200px;
      min-width: 40px;
      padding: 0 1rem;
      position: sticky;
      top: 0;
      background: #191f41;
      z-index: 1;
      color: white;
    }
  }

  tr {
    // display: grid;
    height: 4rem;
    margin-top: 0.5rem;
    border-radius: 10px;
    border-radius: 10px;
    background: #fbfbfb;

    &:hover {
      transform: scale(1.01);
    }

    &:nth-child(odd) {
      background: #fbfbfb;
      // background: red;
    }

    &:nth-child(even) {
      background: #fff;
    }
  }

  td {
    padding: 1rem 1rem;
    position: relative;
    max-width: 200px;
    min-width: 40px;
    font-size: 13px;

    // border: 1px solid #dddddd;

    // white-space: normal;
    // word-wrap: break-word;

    // position: relative;
    // display: flex;
    // align-items: center;
    // border-right: none;
    // border-top: none;
    // font-size: 0.85rem;

    &:nth-child(1) {
      border-radius: 10px 0px 0px 10px;
    }

    &:nth-last-child(1) {
      border-radius: 0px 10px 10px 0px;
    }
  }

  thead {
    border-radius: 10px;

    th {
      padding: 0 1rem;

      &:nth-child(1) {
        border-radius: 10px 0px 0px 10px;
      }

      &:nth-last-child(1) {
        border-radius: 0px 10px 10px 0px;
      }
    }

    tr {
      //display: grid;

      &:nth-child(1) {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
        color: white;
        border-radius: 10px;
        white-space: nowrap;
        height: 40px;
        background: #191f41;
      }
    }
  }
}

// * {
//     box-sizing: border-box;
// }

// table {
//     border-collapse: collapse;
//     width: 100%;
//     table-layout: fixed;
// }

// td,
// th {
//     padding: 5px;

//     border-right: 1px solid #ccc
// }

// thead tr {
//     background: #888;
//     color: #eee;
//     display: grid;
//     position: relative;
// }

.drowpside-right {
  /* border: 1px solid #E9EBF3; */
  padding: 1rem;
  border-radius: 10px;
  /* position: absolute; */
  background: white;
  z-index: 99;
  top: 46px;
  right: 0rem;
  height: calc(100vh - 55px);
  overflow: scroll;
  padding-top: 2rem;
  width: 100%;
}
