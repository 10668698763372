.main-menu-main {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #5A5656, $alpha: 0.73);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  .main-menu-modal {
    width: 29%;
    // height: 50vh;
    background: #ffffff;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6vh 0;
    animation: be-big 0.3s 1 linear;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
    .main-menu-user-details {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 4vh;
      img {
        height: 12vh;
        width: 12vh;
        border-radius: 50%;
        margin-bottom: 3vh;
      }
      h3 {
        font-weight: 700;
      }
      p {
        font-size: 85%;
      }
      h3,
      p {
        margin: 0;
      }
    }
    .main-menu-options {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;
      .single-option-wrapper {
        width: 26%;
        margin-bottom: 2vh;
        div {
          width: 100%;
          border: 1px solid #e5e5e5;
          aspect-ratio: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 15px;
          img {
            height: 6vh;
            width: 6vh;
          }
          &:hover {
            background: #f1f1f1;
            cursor: pointer;
            transition: 0.3s;
          }
        }
        .disable-it {
          pointer-events: none;
          img {
            opacity: 0.25;
          }
        }
        p {
          font-size: 85%;
          margin: 0;
          margin-top: 10px;
        }
      }
    }
  }
}
