.deletedEditingButton {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.85px;
  text-align: center;
  color: #ffffff;
  width: 174px;
  height: 39px;

  border-radius: 10px;
  background: linear-gradient(89.14deg, #f04e8c 0.79%, #f8ba4e 143.99%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    margin-right: 3px;
  }

  &:hover {
    transform: scale(1.03) translatex(-3px);
  }
}

.profileTop {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .svg {
    cursor: pointer;

    &:hover {
      transform: scale(1.1) translatex(3px);
    }
  }
}

.profiletab {
  background: var(--main-blue);
  width: 100%;
  height: 49px;
  display: flex;
  align-items: center;
  gap: 2.5rem;
  padding: 0 2.5rem;
  border-radius: 10px;
  margin-top: 2rem;

  .innder {
    display: flex;
    align-items: center;
    gap: 2.5rem;
  }

  .separartor {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .itemsdata {
    border: 1px solid #191f41;
    border-radius: 20rem;

    cursor: pointer;
    /* Other styles */
    width: 100px;
    height: 40px;
    padding: 12px;
    width: 110px;
    height: 31px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;

    &:hover {
      transform: scale(1.03) translatex(-3px);
    }
  }

  .items {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;

    height: 33px;
    color: #fff;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    border-radius: 35px;
    cursor: pointer;

    &:hover {
      transform: scale(1.08) translatex(-3px);
    }
  }
}

.history-page {
  width: 100%;

  margin-top: 2rem;
  // padding-bottom: 2rem;
  position: relative;
  position: relative;
  height: calc(100vh - 300px);
  overflow: scroll;

  .bottom-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0rem;
    width: 100%;
  }

  .save {
    width: 169px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: linear-gradient(270deg, #f04e8c 0%, #f8ba4e 103.98%);
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 700;
    line-height: 20.72px;
    text-align: center;
    color: white;
    cursor: pointer;

    &:hover {
      transform: scale(1.03);
    }
  }

  .goback {
    width: 169px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: linear-gradient(270deg, #f04e8c 0%, #f8ba4e 103.98%);
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 700;
    line-height: 20.72px;
    text-align: center;
    color: white;
    cursor: pointer;

    &:hover {
      transform: scale(1.03);
    }
  }

  .text {
    color: var(--font-color);
    margin-bottom: 1rem;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.29px;
    text-align: left;
  }

  .historytable {
    padding: 1.5rem 1.5rem;
    border: 1px solid #e5e5e5;
    cursor: pointer;
    color: var(--font-color);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;

    &:hover {
      transform: scaleY(1.1);
    }

    div {
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 500;
      line-height: 15.85px;
      text-align: left;
    }
  }
}

.action-page {
  width: 100%;
  height: 100%;
  margin-top: 2rem;

  .table-tab {
    display: flex;
    align-items: center;
    gap: 2%;
    flex-wrap: wrap;
  }

  .cardlist {
    width: 23.5%;
    height: 14rem;
    border: 1px solid #e5e5e5;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    border-radius: 15px;

    &:hover {
      transform: scale(1.03) translateX(4px);
    }
  }

  .name {
    width: 110px;
    height: 110px;
    background: #d9d9d980;
    border-radius: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 500;
    line-height: 30.48px;
    text-align: center;
    color: var(--font-color);
  }

  .title {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 14.63px;
    text-align: center;
    color: var(--font-color);
    margin-top: 0.9rem;
  }
}
