.academyPage {
  background: var(--light-bg);
  padding: 6rem 0;
  // height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  // align-items: center;

  & > .academy {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;

    & > .platformTitle {
      background: var(--font-color);
      color: white;
      padding: 0.5rem 1.5rem;
      font-size: 1.2rem;
      font-weight: 500;
      width: max-content;
      border-radius: 3px;
    }

    & > .title {
      font-size: 3rem;
      font-weight: 500;
      width: 100%;
      // max-width: 800px;
      text-align: center;
      font-family: "Montserrat";
    }

    & > .desc {
      font-size: 1.4rem;
      font-weight: 380;
      font-family: "Montserrat";
      text-align: center;
      line-height: 1.5;
    }

    & > .academyBox {
      display: grid;
      grid-template-areas:
        "a b c"
        "d e e";
      gap: 1rem;
      width: 100%;
      margin-top: 2rem;

      & > .eachBox {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        // width: 31%;
        border-radius: 10px;
        gap: 6rem;
        border: 1px solid #e5e7eb;
        background: white;
        padding: 2.5rem;
        transition: all linear 0.3s;

        &:hover {
          background: var(--font-color);
          color: white;
        }

        & > .imageBox {
          width: 25%;
          aspect-ratio: 1/1 !important;

          & > img {
            width: 100%;
            height: 100%;
          }
        }

        & > .contentBox {
          width: 100%;
          font-size: 1.5rem;
          font-weight: 500;
          font-family: "Montserrat";
          display: flex;
          align-items: center;
        }

        & > .arrowSvg {
          width: 2rem;
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: #f3f4f5;
          color: var(--font-color);
          // background: gray;
        }

        & > .lastAcademyBox {
          background: #ffffffbf;
          padding: 1.5rem 2rem;
          border-radius: 0 10px 10px 0;
          width: max-content;
          padding-right: 30%;
          font-weight: 550;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;

          & > .desc {
            font-size: 1rem;
            font-weight: 450;
          }
        }
      }

      & > :nth-child(1) {
        grid-area: a;
      }
      & > :nth-child(2) {
        grid-area: b;
      }
      & > :nth-child(3) {
        grid-area: c;
      }
      & > :nth-child(4) {
        grid-area: d;
      }

      & > :last-child {
        // width: 66.66%;
        grid-area: e;
        background-image: url("../../../assets/images/pages/home/academy/financeindia.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: center;
        padding-left: 0;
        justify-content: center;
      }
    }

    & > .linkBtn {
      font-family: "Montserrat";
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 0.6rem;
      cursor: pointer;
      margin-top: 2rem;

      & > span {
        background: rgb(34 40 30/1);
        padding: 5px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 300;
        transition: all linear 0.3s;
      }

      &:hover {
        & > span {
          background: rgb(243, 243, 101);
          color: rgb(34 40 30/1);
          translate: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .academyPage {
    flex-direction: column;

    & > .academy {
      padding: 0 1.5rem;

      & > .academyBox {
        grid-template-areas:
          "a"
          "b"
          "c"
          "d"
          "e";
      }
    }
  }
}
