.kanbanBoard {
  display: flex;
  gap: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 2rem;

  & > .kanbanStage {
    background-color: #fff;
    width: 310px;

    & > .stageHeader {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      color: var(--main-blue);
      padding-left: 0;

      & > h3 {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: calc(100% - 130px);
      }

      & > .addItemBtn {
        border: none;
        color: #ffffff;
        font-size: 1rem;
        padding: 5px;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        background: linear-gradient(89.14deg, #f04e8c 0.79%, #f8ba4e 143.99%);

        &:hover {
          background: var(--main-blue);
        }
      }
    }

    & > .stageItems {
      min-height: 100px;
      padding: 10px 0px;

      & > .kanbanItem {
        background-color: #ffffff;
        padding: 15px 10px;
        border-radius: 7px;
        margin-bottom: 10px;
        border: 1px solid #e1e1e1;
        cursor: grab;
        display: flex;
        flex-direction: column;
        gap: 0.7rem;

        & > .title {
          font-size: 1.15rem;
          font-weight: 510;
          color: var(--main-blue);
          line-height: 1.6;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        & > .subItem {
          border: 1px solid #e1e1e1;
          border-radius: 6px;
          font-size: 0.95rem;
          font-weight: 500;
          color: var(--main-blue);

          & > :nth-child(1) {
            padding: 0.8rem 1.2rem;
            border-radius: 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // transition: all linear 0.15s;

            // &:hover {
            //   background: var(--main-blue);
            //   color: white;
            // }
          }

          & > .miniClientBtns {
            padding: 0.8rem 1.2rem;
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            & > div {
              border-radius: 50%;
              width: 2.7rem;
              height: 2.7rem;
              border: 1px solid #e1e1e1;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.8rem;
              transition: all linear 0.15s;

              &:hover {
                background: var(--main-blue);
                color: white;
              }
            }

            & > .eachJob {
              position: relative;
            }
          }
        }

        & > .subAddBtns {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 3px;
          color: var(--main-blue);
          width: 100%;

          & > div {
            display: flex;
            align-items: center;
            gap: 9px;
            font-size: 0.8rem;
            font-weight: 500;
            cursor: pointer;
            padding: 0.6rem 1rem;
            border: 1px solid #e1e1e1;
            border-radius: 6px;
            width: 100%;
            justify-content: center;

            &:hover {
              color: white;
              background: var(--main-blue);
            }
          }
        }

        & > textarea {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          padding: 5px;
          box-sizing: border-box;
          resize: vertical;
          min-height: 50px;
        }

        pre {
          white-space: pre-wrap;
        }

        & > .editBtns {
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
          gap: 10px;
          width: 100%;
          margin-top: 1rem;

          button {
            all: unset;
            width: 50%;
            border-radius: 7px;
            color: white;
            font-size: 1rem;
            padding: 10px 5px;
            text-align: center;
            cursor: pointer;
          }

          & > :nth-child(1) {
            background: var(--main-blue);
          }
          & > :nth-child(2) {
            background: #d64e52;
          }
        }

        & > .contentTextArea {
          border: none;
        }
      }
    }

    .jobDropdown {
      font-size: 0.95rem;
      color: var(--font-color) !important;
      position: absolute;
      z-index: 1;
      padding: 1rem 12px;
      border-radius: 8px;
      background: white;
      border: 1px solid #e5e5e5;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      box-shadow: 0px 4px 4px 0px #00000040;
      width: 250px;
      top: calc(100% + 1rem);
      left: 0;

      & > .searchDiv {
        padding: 0.75rem 1rem;
        border-radius: 6px;
        border: 0.5px solid #b8b8bb33;
        outline: none;

        &::placeholder {
          color: #191f4133;
        }

        &:focus {
          border: 0.5px solid var(--main-blue);
        }
      }

      & > .allOptions {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        font-size: 0.85rem;

        & > div {
          padding: 0.9rem 1.5rem;
          border: 1px solid #e5e5e5;
          border-radius: 6px;
          cursor: pointer;
          font-weight: 500;
          transition: all linear 0.3s;

          &:hover {
            background: var(--main-blue);
            color: white;
          }
        }
      }
    }

    .actionBtnsContainer {
      position: relative;

      & > svg {
        cursor: pointer;
      }
    }
  }
}

.addBtn {
  height: 30px;
  width: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  font-weight: 600;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  position: absolute;
  right: 0;
  margin-right: 50px;
  &:hover {
    color: white;
    background: var(--main-blue);
    cursor: pointer;
  }
}

.parent {
  position: relative; /* Ensure the parent is positioned */
  width: 100vw;
  height: 100vh;
  background-color: #f0f0f0; /* Example background */
}
.jobPopup {
  position: absolute;
  top: 58%;
  left: 60%;
  transform: translate(-58%, -60%);
  /* Optionally, set a width and height */
  width: 732px; /* Adjust as needed */
  height: 419px; /* Adjust as needed */
  /* Additional styles */
  background-color: white; /* Example background */
  border: 1px solid #ccc; /* Example border */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Example shadow */
  border-radius: 10px;
  padding: 28px 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  z-index: 10;
  gap: 1.5rem;
}
.inputCard {
  // width: 289px;
  width: 100%;
  height: 59px;
  padding: 16px 18px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div:nth-child(1) {
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 3px;
  }
  div:nth-child(2) {
    font-size: 0.85rem;
    font-weight: 400;
    input {
      font-size: 0.85rem;
      font-weight: 400;
      &::placeholder {
        font-size: 0.85rem;
        font-weight: 400;
      }
    }
  }
}
.lable {
  font-size: 0.85rem;
  font-weight: 500;
  padding-bottom: 10px;
}
.submitBtn {
  background: linear-gradient(89.14deg, #f04e8c 0.79%, #f8ba4e 143.99%);
  width: 289px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 5px;
  margin-top: 29px;
  cursor: pointer;
}
