@import "../../assets/scss/colors";
@import "../../assets/scss/mixin";

.settingsModal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  .overlayClose {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
  }
  .settingsCard {
    z-index: 1;
    background: white;
    height: 70%;
    width: 90%;
    max-width: 550px;
    margin: auto;
    border-radius: 30px;
    border: 1px solid $primary-color;
    display: flex;
    flex-direction: column;
    color: $primary-color;
    overflow: hidden;
    position: relative;
    .loadingAnim {
      position: absolute;
      background: #ffffffe0;
      display: flex;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .inCard {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0;
      .logo {
        width: 100%;
        padding: 30px 20%;
        margin-bottom: 20px;
        border-bottom: 1px solid $border-color;
      }
      .title {
        width: 100%;
        padding: 60px 20%;
        padding-bottom: 50px;
        // margin-bottom: 20px;
        // border-bottom: 1px solid $border-color;
      }
      .settingCards {
        height: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 2% 0;
        .settingCard {
          display: flex;
          width: 80%;
          flex: 0 0 24%;
          border: 1px solid $border-color;
          border-radius: 20px;
          align-items: center;
          justify-content: space-between;
          padding: 0 30px;
          color: $primary-color;
          text-decoration: none;
          @include btn-animate($primary-color);
          span {
            font-size: 22px;
            font-weight: 500;
          }
          .icon {
            height: 20px;
            width: auto;
          }
        }
        &.scroll {
          overflow-y: scroll;
          display: flex;
          flex-direction: column;
          justify-content: unset;
          .settingCard {
            margin-bottom: 25px;
            flex: 0 0 16%;
            justify-content: unset;
            .icon {
              height: 40px;
              width: 40px;
              object-fit: contain;
              margin-right: 10px;
            }
            span {
              font-size: 22px;
              font-weight: 600;
            }
          }
        }
      }
      .otpView {
        height: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2% 0;
        .label {
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 10%;
        }
        .otpInputWrapper {
          width: 70%;
          justify-content: space-between;
          margin-bottom: 4%;
          > div {
            flex: 0 0 20%;
            height: 90px;
            .otpInput {
              width: 100% !important;
              height: 100%;
              border: 2px solid $border-color;
              border-radius: 8px;
              font-size: 28px;
            }
          }
        }
      }
    }
    .footerBtns {
      border-top: 1px solid $border-color;
      width: 100%;
      height: 80px;
      display: flex;
      color: $text-color;
      .btnSettings {
       
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 24px;
        background: $app-color;
        color: white;
        @include btn-animate(white);
      }
    }
  }
}

#resetThisRoot {
  all: initial;
  * {
      all: unset;
  }
}


