.type-of-asset {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5vh;
  .t-o-a-title {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 5vh 0;
    h3,
    p {
      margin: 0;
    }
    h3 {
      font-weight: 700;
    }
  }
  .t-o-a-input {
    width: 80%;
    border: 1px solid #e5e5e5;
    margin-bottom: 5vh;
    input {
      width: 100%;
      padding: 5px;
      background: transparent;
      border: 0;
    }
  }
  .t-o-a-list {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .type-wrapper {
      width: 25%;
      div {
        width: 100%;
        aspect-ratio: 1;
        border: 1px solid #e5e5e5;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          background: #f1f1f1;
        }
        img {
          height: 40%;
          width: 40%;
        }
      }
      p {
        font-size: 90%;
        margin-top: 10px;
        font-weight: 500;
      }
    }
    .disable-it {
      opacity: 0.25;
      pointer-events: none;
    }
  }
}
