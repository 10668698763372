.owlCarousal {
  width: 100%;
  overflow: hidden;

  & > div {
    height: 100%;
    // width: 250px !important;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 3.5rem 0;

    img {
      height: 37px;
    }

    // & > div {
    //   width: 100%;

    //   & > div {
    //     width: 100%;

    //     & > div {
    //       width: max-content !important;
    //       padding: 0 1.5rem;

    //       div {
    //         display: flex !important;
    //         justify-content: center !important;
    //         align-items: center !important;
    //       }
    //     }
    //   }
    // }
  }

  .carouselItem {
    width: max-content !important;
  }
}
