.kanban-board {
  display: flex;
  gap: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 2rem;
}

.kanban-stage {
  background-color: #fff;
  // border-radius: 5px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.stage-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  color: var(--main-blue);
  padding-left: 0;
  // border-bottom: 1px solid #ddd;
}

.stage-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  // background-color: #ff6b6b;
  margin-right: 10px;
}

.stage-items {
  min-height: 100px;
  padding: 10px 0px;
}

.kanban-item {
  background-color: #ffffff;
  padding: 15px 10px;
  border-radius: 7px;
  margin-bottom: 10px;
  border: 1px solid #e5e5e5;
  cursor: grab;

  textarea {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 5px;
    box-sizing: border-box;
    resize: vertical;
    min-height: 50px;
  }

  pre {
    white-space: pre-wrap;
  }
  .edit-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    gap: 10px;
    width: 100%;
    margin-top: 1rem;

    button {
      all: unset;
      width: 50%;
      border-radius: 7px;
      color: white;
      font-size: 1rem;
      padding: 10px 5px;
      text-align: center;
      cursor: pointer;
    }

    & > :nth-child(1) {
      background: var(--main-blue);
    }
    & > :nth-child(2) {
      background: #d64e52;
    }
  }
}

.contentTextArea {
  border: none;
}

.add-item-btn {
  border: none;
  color: #ffffff;
  font-size: 1rem;
  padding: 5px;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(89.14deg, #f04e8c 0.79%, #f8ba4e 143.99%);
}
