.crmUsers {
  padding: 2rem;
  height: 100%;
  width: 100%;
  padding-bottom: 0;

  & > .allLeads {
    border: 1px solid #e5e5e5;
    height: 100%;
    overflow: hidden;
    background: white;
    border-bottom: 0;
    overflow-y: hidden;

    & > .nav {
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .filterBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 51px;
        height: 50px;
        border-right: 1px solid #e5e5e5;
        position: relative;

        & > svg {
          fill: var(--font-color);
          transition: all linear 0.3s;

          &:hover {
            fill: var(--main-color);
          }
        }

        & > .dropdownContent {
          position: absolute;
          top: 70%;
          left: 55%;
          padding: 1rem;
          background: white;
          z-index: 6;
          border: 1px solid #e1e1e1;
          box-shadow: 0px 4px 4px 0px #5a565640;
          border-radius: 10px;
          // min-width: calc(max-content + 4rem);
          min-width: 250px;

          & > .closeBtn {
            position: absolute;
            top: 8px;
            right: 8px;
            font-weight: 550;
            cursor: pointer;
            color: var(--main-color);
            z-index: 2;
            border: none;
          }

          & > .eachFilterItem {
            padding: 0.7rem 0.5rem;
            font-size: 1rem;
            font-weight: 400;
            cursor: pointer;
            white-space: nowrap;
            border: none;
            // padding-right: 4rem;

            & > svg {
              rotate: -90deg;
            }

            &:hover {
              color: var(--main-color);
            }
          }
        }
      }

      & > .actionBtn {
        display: flex;
        align-items: center;
        gap: 1rem;

        & > div {
          border: 1px solid #e5e5e5;
          border-radius: 35px;
          padding: 0.5rem 2.5rem;
          cursor: pointer;
          font-size: 0.9rem;
          transition: all ease-in 0.2s;

          &:hover {
            background: #e5e5e5;
          }
        }
      }

      & > .actionBtnsDownload {
        & > div {
          font-size: 0.9rem;
          font-weight: 500;
          cursor: pointer;
          transition: all linear 0.3s;

          &:hover {
            background: var(--main-color);
            color: white;
          }
        }
      }

      & > .navRightBox {
        display: flex;
      }

      .filterItems {
        margin-left: auto;
        border-left: 1px solid #e5e5e5;
        position: relative;

        & > div {
          height: 50px;

          & > div {
            border: none;
            // border-bottom: 1px solid #e5e5e5;
            // border-left: 1px solid #e5e5e5;
            border-radius: 0;
          }
        }

        & > .addStaffGroup {
          aspect-ratio: 1/1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.5rem;
          cursor: pointer;
          transition: all linear 0.3s;

          &:hover {
            color: var(--main-color);
          }
        }

        & > .dropdownContentRight {
          position: absolute;
          top: 110%;
          right: 0%;
          padding: 1rem;
          background: white;
          z-index: 6;
          border: 1px solid #e1e1e1;
          box-shadow: 0px 4px 4px 0px #5a565640;
          border-radius: 10px;
          // min-width: calc(max-content + 4rem);
          min-width: 250px;
          height: max-content;
          overflow-y: scroll;
          max-height: 75vh;

          & > .lodingcontent {
            width: 100%;
            height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            & > .loadingmessage {
              color: #5f6163;
              text-align: center;
              font-family: Montserrat;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              /* 133.333% */
              margin-top: 3rem;
            }
          }

          & > .closeBtn {
            position: absolute;
            top: 8px;
            right: 8px;
            font-weight: 550;
            cursor: pointer;
            color: var(--main-color);
            z-index: 2;
            border: none;
          }

          & > .eachFilterItem {
            padding: 0.7rem 0.5rem;
            font-size: 1rem;
            font-weight: 400;
            cursor: pointer;
            white-space: nowrap;
            border: none;
            // padding-right: 4rem;

            & > svg {
              rotate: -90deg;
            }

            &:hover {
              color: var(--main-color);
            }
          }

          & > .formFields {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding-bottom: 2rem;

            & > .submitBtn {
              padding: 1rem 3rem;
              border-radius: 10px;
              background: var(--main-color);
              color: white;
              cursor: pointer;
              outline: none;
              border: none;
              width: max-content;
              transition: all linear 0.3s;
              margin-top: 2.3rem;

              &:hover {
                scale: 1.05;
              }
            }
          }
        }
      }
    }

    & > .tableContainer {
      overflow-y: auto;
      height: calc(100% - 2rem);
      width: 100%;
      /* Set the container width to 100% or any other desired value */
      overflow-x: auto;
      /* Allow horizontal scrolling */
      // border-left: 0.25px solid #e5e5e5;
      // border-right: 0.25px solid #e5e5e5;
      position: relative;
      padding-bottom: 1.5rem;

      &::-webkit-scrollbar {
        display: block;
        width: 7px;
        /* Set the width of the table's scrollbar */
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--font-color);
        /* Set the color of the scrollbar thumb */
        border-radius: 5px;
        /* Set the border-radius of the thumb */
        height: 80px;
        transition: all linear 0.3s;
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--main-color);
        /* Set the color when the scrollbar thumb is hovered */
      }

      table {
        border-collapse: collapse;
        min-width: 240%;
        /* Set the table width to 110% to ensure horizontal scrollbar */
        // margin-bottom: 20px;
        table-layout: fixed;
        /* Ensures equal column width for horizontally scrolling table */
        width: max-content;

        // tbody {
        //   & > :last-child {
        //     & > td {
        //       border-bottom: none;
        //     }
        //   }
        // }

        th,
        td {
          border: 1px solid #dddddd;
          text-align: left;
          white-space: normal;
          word-wrap: break-word;
          padding: 0.75rem 1rem;
          position: relative;
          display: flex;
          align-items: center;
          border-right: none;
          border-top: none;
          font-size: 0.85rem;

          & > :nth-child(3) {
            width: 50px !important;
          }

          & > input {
            width: 1.25rem;
            height: 1.25rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        th {
          white-space: nowrap;
          font-weight: 550;
          font-size: 0.85rem;
          padding: 0.7rem 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > :nth-child(3) {
            border-left: none;
            width: 50px;
          }

          & > svg {
            cursor: pointer;
            fill: var(--main-color);
          }
        }

        thead {
          & > tr {
            & > th {
              border-top: none;
            }
          }
        }

        tr {
          & > :nth-child(1) {
            border-left: none;
            width: 50px;
          }

          // &> :nth-child(3) {
          //   border-left: none;
          //   width: 200px;
          // }

          & > :last-child {
            border-right: none;
          }
        }

        tr {
          display: grid;
          grid-template-columns: min-content repeat(15, 1fr);
        }

        tr > :first-child {
          /* Set the width you want for the first child */
          width: 50px;
          /* Replace 200px with your desired width */
          /* Position the first child in the first column */
          grid-column: 1;
        }

        .detailedItem {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          justify-content: flex-start;

          & > :nth-child(1) {
            padding: 0.5rem;
            border: 0.5px solid #e5e5e5;
            border-radius: 50%;
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;

            & > img {
              width: 70%;
              height: 70%;
            }
          }

          & > :nth-child(2) {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;

            & > :nth-child(1) {
              font-size: 0.9rem;
              font-weight: 550;
              white-space: nowrap;
            }

            & > :nth-child(2) {
              font-size: 0.8rem;
              font-weight: 400;
            }
          }
        }

        td {
          position: relative;
          max-width: auto;
          transition: all linear 0.3s;

          &:hover {
            background: #8080800d;
          }

          & > .dropdownContent {
            position: absolute;
            top: 70%;
            left: 55%;
            padding: 1rem 0;
            background: white;
            z-index: 6;
            border: 1px solid #e1e1e1;
            box-shadow: 0px 4px 4px 0px #5a565640;
            border-radius: 10px;
            min-width: 250px;

            & > .closeBtn {
              position: absolute;
              top: 8px;
              right: 8px;
              font-weight: 550;
              cursor: pointer;
              color: var(--main-color);
              z-index: 2;
              border: none;
            }

            & > .eachFilterItem {
              padding: 0.7rem 1.5rem;
              font-size: 1rem;
              font-weight: 400;
              cursor: pointer;
              white-space: nowrap;
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              width: 100%;
              border: none;
              // padding-right: 4rem;

              & > svg {
                rotate: -90deg;
              }

              img {
                width: 1rem;
                height: 1rem;
                margin-right: 10px;
              }

              & > .subDropdown {
                display: none;
                min-width: 250px;
                left: 100%;
                top: 0;
                color: var(--font-color);
              }

              &:hover {
                color: var(--main-color);

                & > .subDropdown {
                  display: block;
                }
              }
            }
          }

          .dropdownContent {
            position: absolute;
            top: 70%;
            left: 55%;
            padding: 1rem 0;
            background: white;
            z-index: 6;
            border: 1px solid #e1e1e1;
            box-shadow: 0px 4px 4px 0px #5a565640;
            border-radius: 10px;
            min-width: 250px;

            & > .closeBtn {
              position: absolute;
              top: 8px;
              right: 8px;
              font-weight: 550;
              cursor: pointer;
              color: var(--main-color);
              z-index: 2;
              border: none;
            }

            & > .eachFilterItem {
              padding: 0.7rem 1.5rem;
              font-size: 1rem;
              font-weight: 400;
              cursor: pointer;
              white-space: nowrap;
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              width: 100%;
              border: none;
              // padding-right: 4rem;

              & > span {
                display: flex;
                gap: 10px;
                align-items: center;

                & > svg {
                  height: 1rem;
                  width: 1rem;
                }
              }

              & > svg {
                rotate: -90deg;
              }

              & > .subDropdown {
                display: none;
                min-width: 250px;
                left: 100%;
                top: 0;
                color: var(--font-color);
              }

              &:hover {
                color: var(--main-color);

                & > .subDropdown {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

.topTitle {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.inputContainer {
  padding: 1rem 1.5rem;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  font-size: 0.8rem;
  background: white;
  width: 100%;
  max-width: 500px;
  max-height: 400px;
  overflow-y: scroll;
  outline: none;
  transition: all ease-in 0.2s;
}

.dropdownPlaceholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 206px;
  padding: 0px 17px;
}

.dropDownContainer {
  width: 206px;
  height: 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  cursor: pointer;
}

.dropDownContainerOpen {
  padding-top: 15px;
  width: 206px;
  height: 110px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
  // padding: 15px 17px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  cursor: pointer;
}

.dropItem {
  padding: 8px 17px;
  font-size: 0.8rem;

  &:hover {
    background: var(--main-color);
    color: white;
    cursor: pointer;
  }
}

.mandatoryContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 0.8rem;
}

.mandatoryCard {
  width: 111px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;

  &:hover {
    background: var(--main-color);
    color: white;
    cursor: pointer;
  }
}

.mandatoryCardSelected {
  width: 111px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-color);
  color: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  cursor: pointer;
}

.addQBtn {
  width: 100%;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background: white;

  &:hover {
    background: var(--main-color);
    color: white;
    cursor: pointer;
  }
}

.submitBtn {
  width: 206px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  background: var(--main-color);
  color: white;
  margin: 2.1rem 0px;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  cursor: pointer;
}

.fileUpload {
  display: flex;
  padding: 0.7rem 1rem;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  border: 1px solid #f04e8c;
  width: max-content;
  border-radius: 35px;
  margin: 1.5rem 0;

  & > svg {
    color: #f04e8c;
  }

  & > input {
    display: none;
  }
}

.submitBtnDisabled {
  width: 206px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  background: var(--main-color);
  color: white;
  margin: 2.1rem 0px;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  opacity: 0.4;
}

.closeBtn {
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: var(--main-color);
  }
}

.subTitle {
  font-size: 1rem;
  font-weight: 500;
  margin: 1rem 0;
}

.selectOne {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  font-size: 0.9rem;

  & > div {
    padding: 0.7rem 1.5rem;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      border: 1px solid #191f41;
    }
  }
  & > .selectedOne {
    background: linear-gradient(89.14deg, #f04e8c 0.79%, #f8ba4e 143.99%);
    color: white;
    font-weight: 500;
  }
}
