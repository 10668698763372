.workflowPage {
  background: var(--light-bg);
  padding: 6rem 0;
  // height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  // align-items: center;

  & > .workflow {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;

    & > .platformTitle {
      background: var(--main-color);
      color: white;
      padding: 0.5rem 1.5rem;
      font-size: 1.2rem;
      font-weight: 500;
      width: max-content;
      border-radius: 3px;
    }

    & > .title {
      font-size: 3rem;
      font-weight: 500;
      width: 100%;
      // max-width: 800px;
      text-align: center;
      font-family: "Montserrat";
    }

    & > .desc {
      font-size: 1.4rem;
      font-weight: 380;
      font-family: "Montserrat";
      text-align: center;
      line-height: 1.5;
    }

    & > .trullionBox {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
      width: 100%;
      margin-top: 2rem;

      & > .eachBox {
        display: flex;
        cursor: pointer;
        background: white;
        transition: all linear 0.3s;

        &:hover {
          background: var(--main-color);
          color: white;
        }

        & > div {
          // border-radius: 10px;
          border: 1px solid #e5e7eb;
          padding: 1.25rem;
        }

        & > .imageBox {
          width: 25%;
          aspect-ratio: 1/1 !important;

          & > img {
            width: 100%;
            height: 100%;
          }
        }

        & > .contentBox {
          padding: 2.5rem;
          // width: -webkit-fill-available;
          width: 100%;
          font-size: 1.25rem;
          font-weight: 450;
          font-family: "Montserrat";
          display: flex;
          align-items: center;
        }
      }
    }

    & > .linkBtn {
      font-family: "Montserrat";
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 0.6rem;
      cursor: pointer;
      margin-top: 2rem;

      & > span {
        background: rgb(34 40 30/1);
        padding: 5px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 300;
        transition: all linear 0.3s;
      }

      &:hover {
        & > span {
          background: rgb(243, 243, 101);
          color: rgb(34 40 30/1);
          translate: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .workflowPage {
    flex-direction: column;

    & > .workflow {
      padding: 0 1.5rem;

      & > .trullionBox {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
