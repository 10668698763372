.tableContainer {
  border: 1px solid #e5e5e5;
  margin-top: 2rem;
  height: calc(100% - 12rem);
  border-radius: 6px 6px 0 0;
  background: white;

  & > .staffTable {
    width: 100% !important;
    min-width: unset !important;
    border-spacing: 0 !important;
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    color: var(--font-color) !important;

    tr {
      &:hover {
        transform: scale(1) !important;
        background: #3636380d !important;
      }

      th {
        border-radius: 0 !important;
      }

      td {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    & > thead {
      border-bottom: 1px solid #e5e5e5;

      & > tr {
        &:hover {
          transform: scale(1) !important;
        }
      }

      & > .staffHead {
        background: #191f410d !important;
        color: var(--font-color) !important;

        & > th {
          background: #191f410d !important;
          color: var(--font-color) !important;
        }
      }
    }

    tbody {
      top: 0 !important;
      overflow: scroll;

      & > tr {
        border-radius: 0 !important;
        cursor: pointer;

        & > td {
          border-radius: 0 !important;
        }
      }

      th {
        color: var(--font-color) !important;
      }
    }
  }
}

.staffProfile {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  height: 100%;
  padding: 1.2rem 2rem;

  & > .leftContainer {
    width: 250px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: space-between;
    background: white;
    border: 0.5px solid #e5e5e5;
    border-radius: 8px;

    & > .allSections {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      color: var(--font-color);
      font-size: 0.95rem;
      font-weight: 520;

      & > div {
        padding: 0.7rem 1rem;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
      }

      & > .selectedSection {
        background: linear-gradient(89.14deg, #f4e1e8 0.79%, #f7efe2 143.99%);

        & > div {
          background: linear-gradient(89.14deg, #f04e8c 0.79%, #f8ba4e 143.99%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    & > .gradientBtn {
      padding: 0.85rem 2rem;
      width: 100%;
      color: white;
      font-size: 0.9rem;
      font-weight: 550;
      text-align: center;
      border-radius: 8px;
      cursor: pointer;
      background: linear-gradient(89.14deg, #f04e8c 0.79%, #f8ba4e 143.99%);
      transition: all linear 0.2s;

      &:hover {
        scale: 0.98;
      }
    }
  }

  & > .rightContainer {
    width: calc(100% - 250px);
    overflow-y: scroll;
    padding-bottom: 3rem;

    & > .personalDetails {
      background: white;
      border: 0.5px solid #e5e5e5;
      border-radius: 8px;
      padding: 1.3rem;

      & > .profile {
        display: flex;
        align-items: center;
        gap: 1rem;

        & > img {
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 50%;
        }

        & > span {
          font-size: 0.95rem;
          font-weight: 520;
        }
      }

      & > .details {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        margin-top: 1rem;

        & > div {
          padding-top: 1rem;
          padding-right: 1.2rem;

          & > .title {
            font-size: 0.8rem;
          }

          & > .value {
            font-size: 1rem;
            font-weight: 520;
            margin-top: 5px;
          }
        }
      }
    }

    & > .countContainer {
      margin-top: 1.5rem;

      & > .title {
        font-size: 1rem;
        font-weight: 520;
        margin-bottom: 1rem;
      }

      & > .counts {
        display: flex;
        gap: 1rem;

        & > div {
          width: max-content;
          background: white;
          border: 0.5px solid #e5e5e5;
          border-radius: 8px;
          padding: 1.2rem;
          width: 240px;

          & > .title {
            font-size: 0.85rem;
            font-weight: 450;
          }

          & > .value {
            font-size: 1.5rem;
            font-weight: 520;
            margin-top: 1rem;
          }
        }
      }
    }

    & > .breakDown {
      margin-top: 2rem;

      & > .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > .title {
          font-size: 1rem;
          font-weight: 520;
          margin-bottom: 1rem;
        }

        & > .dropdown {
          border: 0.5px solid #e5e5e5;
          border-radius: 8px;
          padding: 0.7rem 1rem;
          font-size: 0.8rem;
          font-weight: 450;
          display: flex;
          gap: 2rem;
          cursor: pointer;
          background: white;
        }
      }

      & > .imageLock {
        position: relative;
        width: 100%;
        margin-top: 0.5rem;
        border: 0.5px solid #e5e5e5;
        border-radius: 8px;
        padding: 4rem;
        background: white;
        display: flex;
        justify-content: center;

        & > img {
          width: 100%;
        }

        & > .lockDiv {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          position: absolute;
          inset: 0;
          border-radius: 8px;
          padding: 1rem 0;
          background: rgba(255, 255, 255, 0.946);

          & > img {
            height: 2.5rem;
          }

          & > div {
            font-weight: 550;
          }

          & > span {
            font-size: 0.9rem;
            opacity: 0.7;
            font-weight: 450;
          }
        }
      }
    }

    & > .allDetails {
      border: 0.5px solid #e5e5e5;
      border-radius: 8px;
      background: white;
      margin-top: 2rem;
      overflow: hidden;

      & > .header {
        padding: 10px 1rem;
        font-size: 1rem;
        font-weight: 550;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f5f5f5;

        & > .editBtn {
          font-size: 0.95rem;
          cursor: pointer;
          color: #0021d9;
        }
      }

      & > .content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 1.5rem 1rem;
        font-size: 0.9rem;

        & > div {
          display: flex;
          align-items: center;

          & > :nth-child(1) {
            width: 350px;
            font-weight: 400;
          }

          & > :nth-child(2) {
            font-weight: 550;
          }
        }
      }
    }
  }
}
