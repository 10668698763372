.container {
  height: 100%;
  padding: 2rem;
  width: 100%;

  & > .titleWrapper {
    display: flex;
    // justify-content: center;
    align-items: center;

    & > .title {
      font-size: 1.5rem;
      font-weight: 600;
      padding-right: 1.5rem;
      color: #191f41;
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }

  & > .allTabs {
    display: flex;
    align-items: center;
    // gap: 1rem;
    font-size: 0.9rem;
    overflow-x: scroll;
    width: 100%;
    margin: 2rem 0;
    font-weight: 450;

    & > div {
      padding: 0.7rem 1.5rem;
      cursor: pointer;
      border-bottom: 2px solid #e9ebf3;
      transition: all linear 0.3s;
      // border-radius: 8px;
      white-space: nowrap;
    }

    & > .selectedTab {
      border-bottom: 2px solid transparent; /* Set the initial border to transparent */
      border-image: linear-gradient(89.14deg, #f04e8c 0.79%, #f8ba4e 143.99%);
      border-image-slice: 1;
      font-weight: 510;
    }
  }

  & > .childContainer {
    height: calc(100% - 8rem);
  }

  & > .editorContainer {
    height: calc(100% - 3rem);
    margin-top: 2rem;
    background: white;
    border-radius: 6px;
    border: 1px solid #e1e1e1;

    & > .header {
      display: flex;

      & > :nth-child(1) {
        width: calc(100% - 300px);
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e1e1e1;
        border-right: 1px solid #e1e1e1;

        & > .stepContainer {
          display: flex;
          align-items: center;
          gap: 1.4rem;

          & > div {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
            font-weight: 450;
            font-size: 0.95rem;

            & > :nth-child(1) {
              height: 2rem;
              width: 2rem;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.9rem;
              font-weight: 450;
              background: #e9ebf3;
            }
          }

          & > .selectedStep {
            font-weight: 510;
            & > :nth-child(1) {
              background: linear-gradient(
                89.14deg,
                #f04e8c 0.79%,
                #f8ba4e 143.99%
              );
              color: white;
            }
          }
        }
      }

      & > :nth-child(2) {
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #e1e1e1;
      }

      & > div {
        padding: 0.7rem 1rem;
      }
    }

    & > .textEditorContainer {
      height: calc(100% - 65px);

      & > div {
        height: 100%;

        & > :nth-child(1) {
          border: none;
          border-bottom: 1px solid #e1e1e1;
        }

        & > :nth-child(2) {
          height: calc(100% - 44px);
          overflow-y: scroll;
          border: none;
        }
      }
    }
  }

  .submitBtn {
    padding: 0.7rem 2rem;
    border-radius: 7px;
    background: var(--main-blue);
    color: white;
    cursor: pointer;
  }
}

.proposalsAndElf {
  height: 100%;

  & > .btnsContainer {
    display: flex;
    align-items: center;
    gap: 1rem;

    & > div {
      padding: 0.7rem 1.5rem;
      border-radius: 8px;
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: 450;
      border: 1px solid #e1e1e1;
      background: white;
    }

    & > .selecteBtn {
      background: linear-gradient(270deg, #f04e8c 0%, #f8ba4e 103.98%);
      color: white;
    }

    & > .backBtn {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      font-weight: 500;

      & > svg {
        font-size: 1.2rem;
        color: var(--main-blue);
      }
    }
  }

  & > .allTemplatesTable {
    width: 100%;
    border-radius: 6px;
    margin-top: 2rem;
    border: 1px solid #e1e1e1;
    background: white;
    border-spacing: unset;

    & > thead {
      width: 100%;

      &:nth-child(1),
      :nth-child(2) {
        width: 35%;
      }

      & > :last-child {
        width: 30%;
      }

      & > th {
        padding: 0.9rem 1.5rem;
        border-bottom: 1px solid #e1e1e1;
        text-align: left;
      }
    }

    & > tbody {
      & > tr {
        width: 100%;

        &:nth-child(1),
        :nth-child(2) {
          width: 35%;
        }

        & > :last-child {
          width: 30%;

          & > .btnsContainer {
            display: flex;
            align-items: center;
            gap: 1rem;

            & > div {
              padding: 0.8rem 1rem;
              border-radius: 6px;
              border: 1px solid #e1e1e1;
              cursor: pointer;
              font-size: 0.85rem;
              font-weight: 450;
              width: 50%;
              text-align: center;
              transition: all linear 0.3s;

              &:hover {
                background: var(--main-blue);
                color: white;
              }
            }
          }
        }

        & > td {
          padding: 0.8rem 1.5rem;
          border-bottom: 1px solid #e1e1e1;
        }
      }

      & > :last-child {
        border: none;
      }
    }
  }

  & > .textEditor {
    height: calc(100% - 4rem);
    border: 1px solid red;

    // .editor-container {
    //   margin: 20px;
    // }

    // #editor {
    //   height: 400px; /* Adjust the height as needed */
    //   background-color: white;
    // }
  }
}
