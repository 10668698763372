//custom checkbox start...

.custom-checkbox-Parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 1.5rem;
}

input {

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
  }

}

.custom-checkbox-title {
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
}

.custom-checkbox {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(72%, -50%);
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.custom-checkbox-straight {
  position: relative;
  top: unset;
  left: unset;
  transform: unset;
}

.custom-checkbox input {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkmark {
  position: absolute;
  top: 1px;
  left: 1px;
  height: 22px;
  /* Adjusted height to accommodate the tick mark */
  width: 22px;
  /* Adjusted width to accommodate the tick mark */
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkedBox {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: var(--main-color);
}

.checkmark.checked {
  /* No longer need to set background color or border here */
  border: 1px solid #e5e5e5;
}

.checkmark svg {
  width: 16px;
  height: 16px;
  color: var(--main-color);
}

/* Add additional styles for the green tick mark */
.checkmark.checked svg {
  display: block;
  /* Replace 'green_tick.png' with the actual path to your green tick mark image */
}

//custom checkbox end...

.copy_icon {
  fill: #5f6163;
  transition: all linear 0.2s;

  &:hover {
    font-weight: 550;
    fill: var(--main-color);
  }
}