.app-assets-main {
  width: 100%;
  height: 100%;
  img {
    height: 20px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  .app-assets-above {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 5vh 0;
    h3 {
      margin: 0;
      font-weight: 700;
      color: #1f304f;
    }
    span {
      font-size: 75%;
    }
    input {
      width: 100%;
      margin-top: 2vh;
      padding: 8px;
      border: 1px solid #e5e5e5;
    }
  }
  .app-assets-wrapper {
    width: 80%;
    height: 50vh;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
    .app-asset-loader-wrapper {
      width: calc(100% / 3);
      display: flex;
      margin-bottom: 3vh;
      &:nth-child(3n + 1) {
        justify-content: flex-start;
      }
      &:nth-child(3n + 2) {
        justify-content: center;
      }
      &:nth-child(3n + 3) {
        justify-content: flex-end;
      }
      .app-asset-loader {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
          width: 100%;
          border: 1px solid #e5e5e5;
          aspect-ratio: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 15px;
          margin-bottom: 10px;
          cursor: pointer;
          button {
            height: 4vh;
            width: 4vh;
            background: #e5e5e5;
            animation: glow-dim 1.5s infinite;
            border: 0;
            border-radius: 2vh;
          }
          &:hover {
            background: #f1f1f1;
            img {
              transform: scale(1.2);
              transition: 0.3s;
            }
          }
        }
        p {
          font-size: 75%;
          color: #e5e5e5;
          background: #e5e5e5;
          animation: glow-dim 1.5s infinite;
        }
      }
    }
    .app-asset-single {
      width: calc(100% / 3);
      display: flex;
      margin-bottom: 3vh;
      &:nth-child(3n + 1) {
        justify-content: flex-start;
      }
      &:nth-child(3n + 2) {
        justify-content: center;
      }
      &:nth-child(3n + 3) {
        justify-content: flex-end;
      }
      .app-asset-single-wrapper {
        width: 70%;
        div {
          width: 100%;
          border: 1px solid #e5e5e5;
          aspect-ratio: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 15px;
          margin-bottom: 10px;
          cursor: pointer;
          img {
            height: 4vh;
            width: 4vh;
          }
          &:hover {
            background: #f1f1f1;
            img {
              transform: scale(1.2);
              transition: 0.3s;
            }
          }
        }
        p {
          font-size: 75%;
        }
      }
    }
  }
}
