.EZDrawer .EZDrawer__container {
  height: calc(100vh - 53px) !important;
  bottom: 0 !important;
  top: auto !important;
  box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.1) !important;
  border: 0.5px solid #e7e7e7;
  border-top: 0.5px solid #e7e7e7;
}

.bookingDrawer {
  .drawrtable {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    padding: 2rem;
    padding-bottom: 0rem;
    padding-bottom: 4px;
    border-bottom: 1px solid #ddd;

    div {
      cursor: pointer;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #5f6163;
      margin-bottom: 0rem;
      cursor: pointer;
      position: relative;

      &:hover {
        transform: scale(1.03);
      }
    }

    .line {
      width: 100%;
      margin-top: 0.7rem;
      background: linear-gradient(0deg, #191f41 0%, #f79540 100%);
      height: 2px;
      position: absolute;
      top: 16px;
    }
  }

  .listofitem {
    padding: 2rem;
    padding-top: 1rem;
    height: calc(100vh - 7rem);
    overflow: scroll;
  }

  .titleseleted {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    color: #5f6163;
    margin-bottom: 0.4rem;
    margin-top: 1.2rem;
  }

  .labelbuttonbokin {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #5f6163;
    font-style: normal;
    font-weight: 400;
    padding-left: 12px;
    border: 1px solid #e5e5e5;

    width: 100%;
    display: flex;
    align-items: center;
    height: 47px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1.5rem;

    &:hover {
      transform: scale(1.02);
    }
  }

  .input {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #5f6163;
    font-style: normal;
    font-weight: 400;
    padding-left: 12px;
    border: 1px solid #e5e5e5;

    width: 100%;

    height: 47px;
    border-radius: 5px;

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px !important;
      border: 1px solid #e5e5e5;
    }
  }
}
