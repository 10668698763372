.loginPage {
  width: 100vw;
  height: 100vh;
  background: #fbfbfb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .topNav {
    position: fixed;
    top: 0;
    left: 0;
    height: 3rem;
    display: flex;
    align-items: center;
    width: max-content;

    & > a {
      font-size: 0.85rem;
      font-weight: 550;
      text-decoration: underline;
      cursor: pointer;
      padding: 0 1rem;
      color: var(--font-color);
    }
  }

  & > .loginCard {
    background: white;
    display: flex;
    border: 1px solid #e7e7e7;
    width: max-content;
    height: max-content;
    max-height: 90%;
    position: relative;

    & > .leftCard {
      width: 100%;
      padding: 3rem;
      border-right: 1px solid #e7e7e7;
      padding-bottom: 4rem;
      display: flex;
      gap: 2rem;
      flex-direction: column;
      justify-content: center;
      padding-right: 3rem;

      & > img {
        height: 6rem;
        object-fit: contain;
        width: max-content;
      }

      & > .title {
        font-size: 1.2rem;
        font-weight: 500;
      }

      & > .allRegistrationOptions {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & > .eachOption {
          display: flex;
          gap: 1rem;
          align-items: center;
          cursor: pointer;
          padding: 5px;
          transition: all linear 0.3s;

          & > .imageContainer {
            height: 4rem;
            width: 4rem;
            border: 1px solid #e7e7e7;
            padding: 5px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            & > img {
              height: 2rem;
              width: 2rem;
            }
          }

          & > .title {
            & > :nth-child(1) {
              font-size: 1rem;
              font-weight: 550;
              margin-bottom: 6px;
            }

            & > :nth-child(2) {
              font-size: 0.8rem;
              font-weight: 400;
            }
          }

          &:hover {
            background: #e7e7e7;
          }
        }
      }

      & > .credentialsContainer {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.7rem;

        & > .title {
          font-size: 1.5rem;
          font-weight: 520;
        }

        .inputContainer {
          border: 0.5px solid #e5e5e5;
          padding: 1rem;
          background: #f5f5f5;
          font-size: 1rem;
          display: block;
          outline: none;
          font-weight: 400;
          border-radius: 5px;
          // max-width: 450px;
          width: 100%;
        }

        & > .sideByContainer {
          display: flex;
          gap: 1rem;

          & > div {
            width: 50%;
          }
        }

        & > .loginMessage {
          font-size: 1rem;
          font-weight: 500;
          color: #d6a86b;
          padding: 0.25rem 0;
        }

        & > .message {
          font-size: 1rem;
          color: var(--font-color);
          font-weight: 450;
          text-decoration: underline;
        }

        & > .buttonsContainer {
          display: flex;
          align-items: center;
          // max-width: 450px;
          width: 100%;
          gap: 2rem;
          margin-top: 3rem;

          & > button {
            width: 100%;
          }
        }

        .submitBtn {
          border-radius: 5px;
          max-width: 450px;
          width: 80%;
          padding: 1rem;
          font-size: 1rem;
          outline: none;
          font-weight: 400;
          color: white;
          background: var(--main-color);
          border: 1px solid var(--main-color);
          outline: none;
          border: none;
          cursor: pointer;
          transition: all linear 0.3s;

          &:hover {
            scale: 1.05;
          }
        }

        .blueBtn {
          border-radius: 5px;
          width: 100%;
          padding: 1rem;
          font-size: 1rem;
          outline: none;
          font-weight: 550;
          color: white;
          background: var(--font-color);
          border: 1px solid var(--font-color);
          outline: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          transition: all linear 0.3s;

          &:hover {
            scale: 1.05;
          }

          & > img {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }
}
