.pipelineSubmenu {
  padding: 2rem;
  height: calc(100% - 2rem);
  width: 100%;
  padding-bottom: 0;
  display: flex;
  gap: 1.5rem;

  & > .mainContainer {
    width: -webkit-fill-available;
    overflow-y: scroll;
    max-width: 1200px;
    margin: auto;
    border-radius: 10px;
    height: 100%;

    & > .headerContainer {
      margin-bottom: 2rem;
    }

    & > .contentContainer {
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      padding: 1rem;
      background: white;

      & > :nth-child(1) {
        margin-top: 0;
      }
    }
  }
}

.submitBtn {
  width: 206px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  background: var(--main-color);
  color: white;
  margin: 2.1rem 0px;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  cursor: pointer;
}

.submitBtnDisabled {
  width: 206px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  background: var(--main-color);
  color: white;
  margin: 2.1rem 0px;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  opacity: 0.4;
}

.closeBtn {
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: var(--main-color);
  }
}
.selectOne {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.9rem;

  & > div {
    padding: 0.7rem 1.5rem;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #e1e1e1;

    &:hover {
      border: 1px solid #191f41;
    }
  }
  & > .selectedOne {
    background: #191f41;
    color: white;
    font-weight: 500;
  }
}

.subTitle {
  font-size: 1rem;
  font-weight: 500;
  margin: 1rem 0;
}

.selectOneGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  font-size: 0.9rem;

  & > div {
    padding: 0.7rem 1.5rem;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      border: 1px solid #191f41;
    }
  }
  & > .selectedOne {
    background: #191f41;
    color: white;
    font-weight: 500;
  }
}

.closeBtn {
  position: absolute;
  top: 8px;
  right: 8px;
  font-weight: 550;
  cursor: pointer;
  color: var(--main-color);
  z-index: 2;
  border: none;
}

.eachFilterItem {
  padding: 0.7rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  // padding-right: 4rem;

  & > svg {
    rotate: -90deg;
  }

  &:hover {
    color: var(--main-color);
  }
}

.selectOne {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  font-size: 0.9rem;

  & > div {
    padding: 0.7rem 1.5rem;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      border: 1px solid #191f41;
    }
  }
  & > .selectedOne {
    background: linear-gradient(89.14deg, #f04e8c 0.79%, #f8ba4e 143.99%);
    color: white;
    font-weight: 500;
  }
}

.fileUpload {
  display: flex;
  padding: 0.7rem 1rem;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  border: 1px solid #f04e8c;
  width: max-content;
  border-radius: 35px;
  margin: 1.5rem 0;

  & > svg {
    color: #f04e8c;
  }

  & > input {
    display: none;
  }
}

.eachJobContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  overflow-y: scroll;

  & > .header {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    & > :nth-child(1) {
      & > .title {
        font-size: 1.35rem;
        font-weight: 510;
        margin-bottom: 7px;
      }

      & > .id {
        font-size: 0.9rem;
      }
    }

    & > .allTabs {
      display: flex;
      gap: 0.6rem;
      padding: 7px 10px;
      border: 1px solid #e1e1e1;
      border-radius: 8px;

      & > div {
        font-size: 0.8rem;
        font-weight: 450;
        cursor: pointer;
        padding: 0.6rem 1.25rem;
        border-radius: 6px;
        transition: all linear 0.3s;

        &:hover {
          color: white;
          background: var(--main-blue);
        }
      }

      & > .selectedTab {
        background: var(--main-blue);
        color: white;
      }
    }
  }

  & > .flexDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1rem;

    & > div {
      width: 50%;
    }
  }

  .eachDetailedBox {
    & > .title {
      font-size: 0.95rem;
      font-weight: 500;
      margin-bottom: 9px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > svg {
        cursor: pointer;
      }
    }

    & > .contentBox {
      padding: 7px 10px;
      border-radius: 8px;
      border: 1px solid #e5e5e5;
      display: flex;
      align-items: center;
      gap: 8px;
      min-height: 3.5rem;

      & > .imageContainer {
        border-radius: 50%;
        height: 3rem;
        width: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9rem;
        text-transform: uppercase;
        border: 1px solid #e1e1e1;

        & > img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
      }

      & > .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 3.5px;

        & > :nth-child(1) {
          font-weight: 510;
          font-size: 0.95rem;
        }

        & > :nth-child(2) {
          font-size: 0.8rem;
        }

        & > .onlineEdit {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          & > svg {
            cursor: pointer;
          }
        }
      }

      & > svg {
        margin-left: auto;
        cursor: pointer;
      }
    }
  }

  .desContainer {
    margin: 1rem 0;

    & > .title {
      font-size: 1rem;
      font-weight: 510;
      margin-bottom: 9px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > svg {
        cursor: pointer;
      }
    }

    & > .contentBox {
      border-radius: 8px;
      border: 1px solid #e5e5e5;
      padding: 1rem 10px;
      min-height: 6rem;
    }
  }

  .taskHistoryContainer {
    margin: 1rem 0;

    & > .title {
      font-size: 1rem;
      font-weight: 510;
      margin-bottom: 8px;
    }

    & > .contentBox {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      & > .eachHistory {
        border: 1px solid #e1e1e1;
        border-radius: 7px;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        & > :nth-child(1) {
          & > .title {
            font-size: 1.1rem;
            font-weight: 500;
            margin-bottom: 7px;
          }

          & > .detailsBtn {
            text-decoration: underline;
            font-size: 0.8rem;
            cursor: pointer;
          }
        }

        & > :nth-child(2) {
          font-size: 0.92rem;
          font-weight: 510;
        }

        & > .completed {
          color: #2ba937;
        }

        & > .inProgress {
          background: linear-gradient(89.14deg, #f04e8c 0.79%, #f8ba4e 143.99%);
          -webkit-background-clip: text; /* Safari/Chrome */
          -webkit-text-fill-color: transparent; /* Safari/Chrome */
          background-clip: text; /* Standard */
          color: transparent; /* Standard */
        }
      }
    }
  }

  .attachmentsContainer {
    margin: 1rem 0;

    & > .title {
      font-size: 1rem;
      font-weight: 510;
      margin-bottom: 9px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > svg {
        cursor: pointer;
      }
    }

    & > .allAttachments {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      margin-top: 1rem;

      & > div {
        width: 20%;
        aspect-ratio: 1/1;
        border: 1px solid #e1e1e1;
        border-radius: 7px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        & > svg {
          font-size: 3rem;
        }
      }

      & > img {
        height: 100%;
        width: 100%;
        border-radius: 8px;
      }
    }
  }
}
