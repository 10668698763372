.footer {
  padding: 5rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--font-color);
  padding-bottom: 2rem;

  & > .mainBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1400px;
    height: 100%;
    width: 100%;
    color: white;

    & > .head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > img {
        height: 100%;
        object-fit: contain;
      }

      & > .downloadBtns {
        display: flex;
        align-items: center;
        gap: 1rem;

        & > .eachStore {
          display: flex;
          gap: 10px;
          align-items: center;
          border-radius: 10px;
          background: #afeedab3;
          padding: 0.5rem;
          cursor: pointer;

          & > svg {
            font-size: 2.5rem;
            color: #045c40;
          }

          & > .content {
            color: #045c40;
            line-height: 1.4;
            & > :nth-child(1) {
              font-size: 0.9rem;
            }

            & > :nth-child(2) {
              font-size: 1.1rem;
              font-weight: 500;
            }
          }
        }
      }
    }

    & > .allOptions {
      display: grid;
      // grid-template-columns: repeat(4, 0.8fr);
      grid-template-columns: 1fr 1.5fr 1fr 2fr;
      // gap: 1.2rem;
      margin-top: 4rem;

      & > div {
        & > .title {
          font-size: 1.3rem;
          font-weight: 700;
        }

        & > .options {
          padding-top: 1rem;
          font-size: 1rem;
          font-weight: 400;

          & > div {
            padding: 0.4rem 0;
            cursor: pointer;
            transition: all linear 0.3s;

            & > a {
              text-decoration: none;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    & > .contact {
      margin-top: 2rem;

      & > .title {
        font-size: 1.3rem;
        font-weight: 550;
        padding-bottom: 1rem;
      }

      & > .allDetails {
        font-size: 1rem;
        font-weight: 550;
        width: 550px;

        & > div {
          padding: 0.25rem 0;
        }

        & > .socialMedia {
          display: flex;
          gap: 10px;
          margin-top: 1rem;

          & > svg {
            color: white;
            font-size: 2.5rem;
            cursor: pointer;
          }
        }
      }

      & > .certifiedNumber {
        text-align: center;
        font-size: 0.9rem;
        font-weight: 500;
        padding: 1rem 0;
      }

      & > .terms {
        text-align: center;
        font-size: 0.9rem;
        font-weight: 500;
        padding-bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .footer {
    padding: 4rem 6% !important;
    padding-bottom: 1.5rem !important;
    & > .mainBox {
      & > .head {
        flex-direction: column;
        align-items: flex-start !important;
        gap: 3rem;

        & > img {
          height: 40px !important;
        }
      }

      & > .allOptions {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;

        & > div {
          & > .options {
            padding-top: 0.8rem !important;
            font-weight: 450 !important;
          }
        }
      }

      & > .contact {
        & > .allDetails {
          width: 100% !important;
        }

        & > .certifiedNumber {
          padding: 2rem 0 !important;
        }
      }
    }
  }
}
