//custom dropdown

.eachCustomDropdown {
  position: relative;
  min-width: 220px;
  height: 3rem;
  cursor: pointer;
  white-space: nowrap;
  transition: all linear 0.3s;

  //margin-bottom:2rem;
  & > .topTitle {
    font-size: 0.9rem;
    margin-top: 1rem;
  }

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 0rem;
    z-index: 2;
    max-height: 400px;
    overflow-y: scroll;
    transition: all ease-in 0.2s;

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:focus {
      outline: var(--main-color);
      border: 1.5px solid var(--main-color);
      background: white;
    }

    & > ul {
      list-style: none;
      cursor: pointer;
      margin: 0;
      margin-top: 1rem;

      & > li {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0rem;

        &:hover {
          transform: scale(1.03);
          color: #0d264c;
        }

        & > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }

      & > li:has(input) {
        padding: 0;

        & > input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          padding: 0.75rem 0rem;
          color: var(--font-color);
          background: transparent;
          transition: all ease-in 0.2s;

          :focus {
            border: 2px solid var(--main-color);
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0.5em;
      /* Adjust the width as needed */
      background: transparent;
      /* Adjust the background color as needed */
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
      /* Adjust the thumb color as needed */
      display: none;
    }
  }
}

.eachCustomInput {
  position: relative;
  min-width: 200px;
  // height: 6rem;
  height: auto;
  cursor: pointer;
  transition: all linear 0.3s;
  //padding-bottom: 5px;
  margin-top: 1rem;
  margin-bottom: 1rem;

  & > .topTitle {
    margin-top: 1rem;
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    font-size: 0.8rem;
    background: white;
    width: 100%;
    max-width: 500px;
    max-height: 400px;
    overflow-y: scroll;
    outline: none;
    transition: all ease-in 0.2s;

    &::placeholder {
      opacity: 1;
      font-size: 0.8rem;
      color: var(--font-color);
      font-weight: 400;
    }
  }

  & > .arrayContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > .inputContainer {
      padding: 1rem 1.5rem;
      border-radius: 5px;
      border: 1px solid #e5e5e5;
      font-size: 0.8rem;
      background: white;
      width: 100%;
      max-width: 500px;
      max-height: 400px;
      overflow-y: scroll;
      outline: none;
      transition: all ease-in 0.2s;
    }
  }
}

.eachCustomTextarea {
  position: relative;
  // min-width: 200px;
  cursor: pointer;
  transition: all linear 0.3s;
  margin-top: 1rem;
  // height: 94px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;

  & > .topTitle {
    margin-top: 1rem;
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  & > .inputContainer {
    padding: 1rem 1.5rem;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    font-size: 0.8rem;
    background: white;
    width: 100% !important;
    // max-width: 500px;
    max-width: 100% !important;
    // overflow-y: scroll;
    outline: none;
    transition: all ease-in 0.2s;
  }
}

.eachCustomImageUpload {
  position: relative;
  min-width: 200px;
  cursor: pointer;
  transition: all linear 0.3s;

  & > .topTitle {
    margin-top: 1rem;
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  & > .imageInput {
    width: 100%;
    background: none;
    border: none;

    img {
      width: 100%;
      height: 8rem;
      border-radius: 5px;
      border: 0.5px solid #e5e5e5;
    }

    & > .imageLabelcustom {
      white-space: nowrap;
      text-align: center;
      margin-top: 1rem;
      color: #5f6163;

      font-size: 0.9rem;
      font-weight: 450;
      cursor: pointer;
      width: 153px;
      height: 43px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 4rem;
      border: 1px solid #ebebeb;
    }

    & > .imageLabel {
      white-space: nowrap;
      text-align: center;
      margin-top: 1rem;
      color: rgba(0, 0, 0, 0.9);
      font-size: 0.9rem;
      font-weight: 450;
      cursor: pointer;
    }

    & > .emptyProfilecustom {
      width: 6rem;
      height: 6rem;
      border-radius: 85px;
      background: #f5f5f5;
      border: 0.5px solid #e5e5e5;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all linear 0.3s;

      & > img {
        width: 3rem;
        height: 3rem;
        border: none;
        transition: all linear 0.3s;
      }

      &:hover {
        border: 0.5px solid var(--main-color);

        & > img {
          height: 4rem;
          width: 4rem;
        }
      }

      @keyframes heartbeat {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.2);
        }

        100% {
          transform: scale(1);
        }
      }
    }

    & > .emptyProfile {
      width: 100%;
      height: 8rem;
      border-radius: 5px;
      background: #f5f5f5;
      border: 0.5px solid #e5e5e5;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all linear 0.3s;

      & > img {
        width: 3rem;
        height: 3rem;
        border: none;
        transition: all linear 0.3s;
      }

      &:hover {
        border: 0.5px solid var(--main-color);

        & > img {
          height: 4rem;
          width: 4rem;
        }
      }

      @keyframes heartbeat {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.2);
        }

        100% {
          transform: scale(1);
        }
      }
    }

    & > .emptyProfileLength {
      height: 6rem;
      width: 100%;
      border-radius: 5px;
      background: #f5f5f5;
      border: 0.5px solid #e5e5e5;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all linear 0.3s;

      & > img {
        width: 3rem;
        height: 3rem;
        border: none;
        transition: all linear 0.3s;
      }

      &:hover {
        border: 0.5px solid var(--main-color);

        & > img {
          height: 4rem;
          width: 4rem;
        }
      }

      @keyframes heartbeat {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.2);
        }

        100% {
          transform: scale(1);
        }
      }
    }
  }
}

.eachCustomSelectMultiple {
  position: relative;
  min-width: 200px;
  cursor: pointer;
  transition: all linear 0.3s;

  & > .topTitle {
    margin-top: 1rem;
    font-size: 0.9rem;
    margin-bottom: 1.2rem;
  }

  & > .mapItems {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    & > div {
      padding: 1rem 1.5rem;
      border-radius: 5px;
      border: 1px solid #e5e5e5;
      font-size: 0.8rem;
      background: white;
      width: 100%;
      max-width: 500px;
      // overflow-y: scroll;
      outline: none;
      transition: all ease-in 0.2s;

      &:hover {
        background: var(--main-color);
        color: white;
      }
    }

    & > .selectedItem {
      border: 1px solid #0d264c;
      color: var(--main-color);
      font-weight: 500;
    }
  }
}

.eachInputDatePicker {
  & > .topTitle {
    margin-top: 1rem;
    font-size: 0.9rem;

    & > img {
      position: absolute;
      top: 1px;
      right: -0.95rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  & > .inputContainer {
    color: var(--font-color);
    padding: 1rem 1.5rem;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    font-size: 0.8rem;
    margin-top: 1rem;
    width: 100%;
    max-width: 500px;
    transition: all linear 0.3s;

    // &:focus {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
    // &:hover {
    //   background: white;
    //   border: 1.5px solid var(--main-color);
    // }
  }
}

.passwordBox {
  width: 100%;
  position: relative;

  .inputContainer {
    border: 0.5px solid #e5e5e5;
    padding: 1rem;
    background: #f5f5f5;
    font-size: 1rem;
    display: block;
    outline: none;
    font-weight: 400;
    border-radius: 5px;
    // max-width: 450px;
    width: 100%;
  }

  & > svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    font-size: 1.2rem;
  }
}

.eachInputDatePicker {
  margin-bottom: 2rem;
}

.astric {
  color: #191f41;
}
